export const isNotOlderThan14Days = (timestamp: number | null | undefined): boolean => {
  if (!timestamp) {
    return false;
  }
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setHours(0, 0, 0, 0);
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

  const entryTimestamp = new Date(timestamp);
  entryTimestamp.setHours(0, 0, 0, 0);
  return entryTimestamp >= twoWeeksAgo;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
