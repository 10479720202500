import styled from '@emotion/styled';
import { colourTextAndIcon3 } from '@ingka/variables/colours-css.js';
import { breakpointXl, fontSize100, fontSize800, radiusS, space300, space50, space75, thicknessThin } from '@ingka/variables';
import { FlexContainer } from '../../../components/FlexContainer';
import { colourNeutral3, colourNeutral5, colourSemanticInformative, colourStaticGrey, colourTextAndIcon1 } from '@ingka/variables/colours-css';

import Text from '@ingka/text';

export const GraphContainer = styled(FlexContainer)`
  height: auto;
  min-width: 50vw;
  position: relative;
  @media screen and (min-width: ${breakpointXl}) {
    min-width: 60vw;
  }
`;
export const GraphHeading = styled.span`
  display: flex;
  gap: ${space50};
`;

export const TooltipText = styled.p`
  min-width: 248px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  gap: ${space75};
`;

export const GrayText = styled(Text)`
  color: ${colourTextAndIcon3};
`;

export const DatepickerInput = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12.5rem;
  background: #fff;
  border: ${thicknessThin} ${colourNeutral5} solid;
  border-radius: ${radiusS};
  height: ${space300};
  font-size: ${fontSize100};
  cursor: pointer;
  padding: 0 ${space75};

  :focus {
    border: 2px solid ${colourSemanticInformative};
  }
`;

export const DateInputsWrapper = styled.div`
  width: 28.125rem;
  display: grid;
  gap: ${space50};
  grid-template-columns: repeat(2, 1fr);
`;

export const Most404sContainer = styled(FlexContainer)`
  border: ${thicknessThin} solid ${colourNeutral3};
  border-radius: ${radiusS};
  flex-direction: column;
  padding: ${space75};
  gap: ${space75};
`;

export const Total404sContainer = styled(FlexContainer)`
  border: ${thicknessThin} solid ${colourNeutral3};
  border-radius: ${radiusS};
  flex-direction: column;
  padding: ${space75};

  h2 {
    font-size: ${fontSize800};
    font-family: 'Noto Sans', sans-serif;
    font-weight: 500;
    color: ${colourTextAndIcon1};
  }

  h4 {
    color: ${colourTextAndIcon1};
  }
`;

export const Most404PagesRows = styled(FlexContainer)`
  flex-direction: column;

  > div {
    padding-top: ${space50};
    padding-bottom: ${space50};
    border-bottom: ${thicknessThin} solid ${colourStaticGrey};
  }

  > div:last-child {
    border-bottom: none;
  }
`;
