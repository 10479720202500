import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@ingka/button';
import arrowPair from '@ingka/ssr-icon/paths/arrow-pair-squarepath';
import barChart from '@ingka/ssr-icon/paths/bar-chart-uptrend';
import dataChart from '@ingka/ssr-icon/paths/data-chart';
import home from '@ingka/ssr-icon/paths/home';
import network from '@ingka/ssr-icon/paths/network';
import person from '@ingka/ssr-icon/paths/person';
import { Context as SideNavContext } from 'hooks/contexts/SideNavContext';
import { Features, useFeatureContext } from 'hooks/contexts/FeatureContext';
import { useCountry } from 'hooks/useCountry';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import * as Styled from '../styles';

export interface MenuItem {
  href: string;
  path: string;
  text: string;
  feature?: string;
  icon: () => React.SVGProps<SVGElement>[];
}

export const navItems = (countryCode?: string): MenuItem[] => [
  {
    text: 'Overview',
    href: '/',
    path: '/',
    icon: home,
  },
  {
    text: 'Sitemaps',
    href: '/sitemaps',
    path: '/sitemaps',
    feature: Features.SITEMAPS,
    icon: network,
  },
  {
    text: 'Graphs',
    href: '/graphs',
    path: '/graphs',
    icon: dataChart,
  },
  {
    text: 'Redirects',
    href: `/redirects/${countryCode}`,
    path: '/redirects',
    icon: arrowPair,
  },
  {
    text: 'Admin Tools',
    href: '/admin/config',
    path: '/admin',
    icon: person,
  },
  {
    text: 'Meta Data',
    href: '/metadata',
    path: '/metadata',
    icon: barChart,
  },
];

export const findLongestMatching = (items: MenuItem[], pathname: string): string => {
  return items.reduce((longestMatching, curr) => {
    if (pathname.startsWith(curr.path) && curr.path.length > longestMatching.length) {
      return curr.href;
    }
    return longestMatching;
  }, '');
};
export const SideNav: React.FC = () => {
  const { pathname } = useLocation();
  const { isAdmin } = useContext(LoginContext);
  const [country] = useCountry();
  const items = navItems(country?.countryCode);
  const {
    state: { isOpen },
  } = useContext(SideNavContext);
  const { featureIsActive } = useFeatureContext();
  const currentLocation = findLongestMatching(items, pathname);

  const isHidden = pathname.match(/^\/(?:redirects\/(?:\w{2}|global)\/.+|metadata\/.+)$/) || pathname.includes('/bookmarklet');

  return isHidden ? null : (
    <Styled.SideNav>
      {items
        .filter((item) => isAdmin() || !item.href.includes('/admin/'))
        .filter((item) => !item.feature || featureIsActive(item.feature))
        .map((item) => (
          <Styled.SideNavItem key={item.href} to={item.href}>
            <Styled.SideNavItemIcon isActive={currentLocation === item.href}>
              <Button ssrIcon={item.icon} iconOnly size={'small'} type={'tertiary'} />
            </Styled.SideNavItemIcon>
            {isOpen && <Styled.SideNavItemName isActive={currentLocation === item.href}>{item.text}</Styled.SideNavItemName>}
          </Styled.SideNavItem>
        ))}
    </Styled.SideNav>
  );
};
