import { FlexContainer } from '../FlexContainer';
import styled from '@emotion/styled';
import { space300 } from '@ingka/variables';

export const LoaderContainer = styled(FlexContainer)<{ minHeight?: string }>`
  height: 100%;
  width: 100%;
  padding-block: ${space300};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '100%')};
`;
