import styled from '@emotion/styled';

export { InfoContainer, InfoBox } from './../styles';

export const FullscreenSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 200px);
`;

interface TreeMapSVGProps {
  site: string;
}

export const TreeMapSVG = styled(FullscreenSVG)<TreeMapSVGProps>`
  .node text {
    font-family: sans-serif;
    font-size: 12px;
    fill: #ffffff;
    pointer-events: none;
  }

  .node text.large {
    font-size: 16px;
    font-weight: bold;
  }

  .link {
    fill: none;
    stroke: #484848;
    stroke-width: 1.5px;
  }

  .node circle {
    fill: #fff;
    stroke: black;
    stroke-width: 2px;
  }

  .node text.plus {
    fill: black;
    font-size: 20px;
  }

  .node rect {
    cursor: default;
    stroke-width: 2px;
    fill: #484848;
    stroke: #000000;
    border-radius: 5px;
  }

  .node.node-internal rect {
    cursor: pointer;
    border-bottom: solid 5px black;
  }

  .node.editorial.populated rect {
    fill: #0058a3;
    stroke: #003562;
  }

  .node.editorial.violation rect {
    fill: #ffdba7;
    stroke: #ffb750;
  }

  .node.editorial.populated.violation rect {
    fill: #ffa524;
    stroke: #af6700;
  }

  .node.editorial rect {
    fill: ${({ site }) => (site ? '#99bcda' : '#0058a3')};
    stroke: ${({ site }) => (site ? '#669bc8' : '#003562')};
  }

  .node.editorial.populated circle {
    stroke: #003562;
  }

  .node.editorial circle {
    stroke: ${({ site }) => (site ? '#669bc8' : '#003562')};
  }

  .node.editorial.violation circle {
    stroke: #ffb750;
  }

  .node.editorial.populated.violation circle {
    stroke: #af6700;
  }

  .node.range rect {
    fill: #ffdb00;
    stroke: #998300;
  }

  .node.range text {
    fill: #000000;
  }

  .node.search rect {
    fill: #0a8a00;
    stroke: #065300;
  }
`;
