import React from 'react';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import checkmarkIcon from '@ingka/ssr-icon/paths/checkmark-circle';
import incorrectIcon from '@ingka/ssr-icon/paths/incorrect';
import informationIcon from '@ingka/ssr-icon/paths/information';
import informationCircleIcon from '@ingka/ssr-icon/paths/information-circle';
import warningIcon from '@ingka/ssr-icon/paths/warning-triangle';
import Tooltip from '@ingka/tooltip';
import * as Styled from '../styles';

export type DashboardCardProps = {
  type: 'negative' | 'cautious' | 'positive' | 'informative';
  title: string;
  text: string;
  onClick?: () => void;
};

type TooltipType = 'nonoptimized' | 'needsreview' | 'totaloptimized' | 'optimized' | 'newpages';

const metaDataTooltips: Record<TooltipType, string> = {
  nonoptimized: 'The amount of pages that needs to be optimized',
  needsreview: 'The amount of pages that needs to be reviewed',
  totaloptimized: 'The amount of pages that are optimized',
  optimized: 'The amount of pages that are optimized in percent',
  newpages: 'The amount of pages that are added in the last 14 days',
};

export const StatusOverviewCard: React.FC<DashboardCardProps> = ({ onClick, type, title, text }) => {
  const icon = {
    negative: incorrectIcon,
    cautious: warningIcon,
    positive: checkmarkIcon,
    informative: informationCircleIcon,
  }[type];

  const DBoxRole = `overview-card-${title.replaceAll(' ', '-').toLowerCase()}`;
  const DBoxTextId = `overview-card-text-${title.replaceAll(' ', '-').toLowerCase()}`;

  return (
    <Styled.StatusOverviewBox role={DBoxRole}>
      <Styled.DashboardBoxHeader type={type}>
        <SSRIcon paths={icon} height="200" />
        <span data-testid={DBoxTextId}>{text}</span>
      </Styled.DashboardBoxHeader>
      <Styled.StatusOverviewText>
        {title}
        <Tooltip tooltipText={metaDataTooltips[title.replace(' ', '').toLowerCase() as TooltipType]} ssrIcon={informationIcon} position={'bottom'} />
      </Styled.StatusOverviewText>
      {onClick && <Button text={'View'} type={'tertiary'} onClick={onClick} size={'small'} />}
    </Styled.StatusOverviewBox>
  );
};
