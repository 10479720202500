import Button from '@ingka/button';
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up-to-line';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import Text from '@ingka/text';
import React from 'react';
import { EntryButtonsProps } from '../';
import { isPublicera } from '../../../opt-util/helpers';
import { useToast } from '../../../hooks/contexts/ToastContext';

type EntryButtonsCompProps = {
  selectedRowsWithPolicy?: { policy: string; docRefId: string }[];
  selectedRows?: string[];
  isMultiplePolicy?: boolean;
  pageName?: string;
  filterType?: string;
} & EntryButtonsProps;

export const EntryButtons: React.FC<EntryButtonsCompProps> = ({
  downloadFile,
  uploadFile,
  deleteClick,
  selectedRowsWithPolicy,
  selectedRows,
  totalRows,
  pageName,
  isMultiplePolicy,
  filterType,
}) => {
  const numSelectedRows = isMultiplePolicy && !!selectedRowsWithPolicy ? selectedRowsWithPolicy.length : !!selectedRows ? selectedRows.length : 0;
  const hasDownloadUploadButtons = !isMultiplePolicy && downloadFile && uploadFile && !isPublicera(filterType);
  const downloadButtonText = `Download ${numSelectedRows > 0 ? `(${numSelectedRows})` : 'all'}`;
  const deleteButtonText = `Delete ${numSelectedRows > 0 ? `(${numSelectedRows})` : ''}`;
  const { toastContent } = useToast();
  const isDownloadBtnDisabled = toastContent.type === 'download';
  const isDeleteButtonDisabled = isMultiplePolicy
    ? selectedRowsWithPolicy && selectedRowsWithPolicy?.length < 1
    : selectedRows && selectedRows?.length < 1;
  return (
    <>
      {hasDownloadUploadButtons && (
        <>
          <Button
            ssrIcon={arrowDown}
            text={downloadButtonText}
            type={'secondary'}
            onClick={() => downloadFile()}
            data-testid={`${pageName}-download-btn`}
            size={'small'}
            disabled={isDownloadBtnDisabled}
          />

          <Button
            ssrIcon={arrowUp}
            text={'Upload'}
            type={'secondary'}
            onClick={() => uploadFile()}
            data-testid={`${pageName}-upload-btn`}
            size={'small'}
          />
        </>
      )}
      {deleteClick && (
        <Button
          ssrIcon={trashCan}
          text={deleteButtonText}
          type={'secondary'}
          onClick={() => deleteClick()}
          disabled={isDeleteButtonDisabled}
          data-testid={`${pageName}-delete-btn`}
          size={'small'}
        />
      )}
      <Text tagName="span" bodySize="m">
        {totalRows} items found
      </Text>
    </>
  );
};
