import styled from '@emotion/styled';
import IngkaTable from '@ingka/table';
import { space125, space50 } from '@ingka/variables/design-tokens';

export const Table = styled(IngkaTable)<{ offsettop?: number }>`
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  height: ${(props) => `calc(100vh - ${props.offsettop}px - ${space125} - ${space50})`};

  tbody,
  td {
    vertical-align: middle;
    word-break: break-word;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

export const AlignedHeader = styled.th<{ rightAlign?: boolean }>`
  text-align: ${({ rightAlign = false }) => (rightAlign ? 'right' : 'left')} !important;
`;
