import styled from '@emotion/styled';
import { space125 } from '@ingka/variables';
import { FlexContainer } from 'components/FlexContainer';
import RadioButtonGroup from '@ingka/radio-button-group';
import Checkbox from '@ingka/checkbox';

export const ValidityFilterWrapper = styled(FlexContainer)`
  height: 100%;
  gap: ${space125};
`;

export const ValidityFilterRadioButtonGroup = styled(RadioButtonGroup)`
  > span:not(:last-child) {
    margin-bottom: ${space125} !important;
  }

  > span {
    flex-direction: row-reverse;
    justify-content: space-between;

    & > input[type='radio'] {
      width: 100% !important;
      left: auto !important;
    }

    & > .radio__label {
      & > label {
        padding: 0;
      }
    }
  }

  > legend {
    margin-bottom: ${space125} !important;
  }
`;

export const ValidityFilterCheckbox = styled(Checkbox)`
  flex-direction: row-reverse;
  justify-content: space-between;

  & > input[type='checkbox'] {
    width: 100% !important;
    left: auto !important;
  }

  & > .checkbox__label {
    margin-top: 0;

    & > label {
      padding: 0;
    }
  }
`;
