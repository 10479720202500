import styled from '@emotion/styled';
import { colourNeutral4, colourNeutral7, colourSemanticInformative } from '@ingka/variables/colours-css';

type EditorContainerProps = {
  compact?: boolean;
};

export const EditorContainer = styled.div<EditorContainerProps>`
  height: ${(props) => (props.compact ? '8rem' : '100%')};
  border-style: solid;
  border-width: 1px;
  border-color: ${colourNeutral4};
  text-align: left;
  padding: 0.2rem 0.2rem;

  &:focus-within {
    border-color: ${colourSemanticInformative};
  }

  &:not(:focus-within):hover {
    border-color: ${colourNeutral7};
    cursor: pointer;
  }

  .editor-link {
    color: inherit;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const LeftTextIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 6px;
`;
