import { createContext, ReactNode, useContext, useState } from 'react';

interface LanguageContextType {
  selectedLanguage: string;
  setSelectedLanguage: (selectedLanguage: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({ children, injectedLanguage }: { children: ReactNode; injectedLanguage?: string }) {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(injectedLanguage || '*');

  return (
    <LanguageContext
      value={{
        selectedLanguage,
        setSelectedLanguage,
      }}
    >
      {children}
    </LanguageContext>
  );
}

export const useLanguageSelection = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
