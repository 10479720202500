import React, { JSX, useState } from 'react';
import Tabs, { TabsProps } from '@ingka/tabs';

export interface TabPanelT extends JSX.Element {
  props: {
    tabPanelId: string;
  };
}

interface TabsCompProps extends TabsProps {
  tabPanels: TabPanelT[];
}

const TabsComponent: React.FC<TabsCompProps> = ({ tabs, tabPanels, defaultActiveTab }) => {
  const [activeTab, setActiveTab] = useState<string>('');

  const tabPanelsWithActiveState = tabPanels.map((panel) => (activeTab === panel.props.tabPanelId || activeTab === '') && panel);

  return <Tabs tabs={tabs} tabPanels={tabPanelsWithActiveState} defaultActiveTab={defaultActiveTab} onTabChanged={(tab) => setActiveTab(tab)} />;
};

export default TabsComponent;
