import React, { useEffect, useRef, useState } from 'react';

export const useComponentOffsetTop = (): {
  componentRef: React.RefObject<HTMLDivElement | null>;
  componentOffsetTop: number;
} => {
  const componentRef = useRef<HTMLDivElement>(null);

  const [componentOffsetTop, setComponentOffsetTop] = useState<number>(0);

  useEffect(() => {
    if (componentRef.current) {
      setComponentOffsetTop(componentRef.current.offsetTop);
    }
  }, [componentRef.current, componentRef.current?.offsetTop]);

  return { componentRef, componentOffsetTop };
};
