import { gql } from 'apollo-boost';
import { OmitTN } from 'opt-util/types';
import { MetaDataCompleteFragment } from '__generated__/types';

const metaDataEntry = gql`
  fragment MetaDataComplete on MetaDataEntry {
    id
    urlSlug
    canonical
    canonicalId
    createdAt
    description
    keywords
    pageTitle
    kategoriseraTitle
    previousKategoriseraTitle
    robots
    ogDescription
    ogImage
    ogTitle
    bodyCopy
    introText
    filterId
    filterName
    filterType
    filters
    h1
    hasProducts
    referenceUrl
    isOptimized
    primaryKeyword
    secondaryKeyword
    publiceraId
    section {
      id
      name
    }
    alternativeKeyword
    suggestedPrimaryKeyword
    suggestedSecondaryKeyword
    suggestedAlternativeKeyword
  }
`;
const metadataDraftEntry = gql`
  fragment MetaDataDraft on UploadRow {
    id
    urlSlug
    canonical
    canonicalId
    description
    keywords
    pageTitle
    robots
    ogDescription
    ogImage
    ogTitle
  }
`;
const metadataVersionDetails = gql`
  ${metaDataEntry}
  fragment MetaDataVersionDetails on MetaDataVersion {
    _id
    author
    ts
    country
    language
    type
  }
`;

const uploadComparison = gql`
  fragment UploadComparison on UploadComparison {
    country
    language
    type
    _id
    errors {
      id
      row
      ids
      type
    }
    deleted
    comparison {
      id
      urlSlug
      canonical
      canonicalId
      description
      keywords
      pageTitle
      kategoriseraTitle
      previousKategoriseraTitle
      robots
      ogDescription
      ogImage
      ogTitle
      bodyCopy
      introText
      filterName
      filterType
      filters
      h1
      referenceUrl
      warnings
      row
      primaryKeyword
      secondaryKeyword
      alternativeKeyword
      suggestedPrimaryKeyword
      suggestedSecondaryKeyword
      suggestedAlternativeKeyword
      original {
        ...MetaDataComplete
      }
    }
  }
  ${metaDataEntry}
`;
const localeDetails = gql`
  fragment LocaleDetails on MetaData {
    country
    language
    type
  }
`;

const upload = gql`
  fragment Upload on UploadVersion {
    _id
    author
    ts
    country
    language
    type
    latest {
      _id
    }
  }
`;

export type ValueKeys = keyof OmitTN<MetaDataCompleteFragment>;
const metaDataEntryOptimized = gql`
  fragment MetaDataExtended on MetaDataEntry {
    id
    urlSlug
    canonical
    canonicalId
    createdAt
    description
    keywords
    pageTitle
    kategoriseraTitle
    previousKategoriseraTitle
    robots
    isOptimized
    needsReview
    ogDescription
    ogImage
    ogTitle
    bodyCopy
    introText
    h1
    hasProducts
    referenceUrl
    primaryKeyword
    secondaryKeyword
    publiceraId
    section {
      id
      name
    }
    alternativeKeyword
    suggestedPrimaryKeyword
    suggestedSecondaryKeyword
    suggestedAlternativeKeyword
    searchConsoleData {
      avg_position
      search_clicks
      search_ctr
      search_impressions
    }
    homeFurnishingBusiness {
      id
      name
    }
    ts
    author
    diffVersionId
    mdToHtml {
      introText
      bodyCopy
    }
    categoryId
    filterId
    filterName
    filterType
    filters
  }
`;

const metaDataEntryById = gql`
  query MetadataEntryById($entryId: String!, $country: String!, $language: String!, $metadataType: String!) {
    getMetadataEntryById(entryId: $entryId, country: $country, language: $language, metadataType: $metadataType) {
      id
      urlSlug
      canonical
      canonicalId
      createdAt
      description
      keywords
      pageTitle
      kategoriseraTitle
      previousKategoriseraTitle
      robots
      isOptimized
      needsReview
      ogDescription
      ogImage
      ogTitle
      bodyCopy
      introText
      h1
      hasProducts
      referenceUrl
      primaryKeyword
      secondaryKeyword
      alternativeKeyword
      suggestedPrimaryKeyword
      suggestedSecondaryKeyword
      suggestedAlternativeKeyword
      searchConsoleData {
        avg_position
        search_clicks
        search_ctr
        search_impressions
      }
      homeFurnishingBusiness {
        id
        name
      }
      ts
      author
      diffVersionId
      mdToHtml {
        introText
        bodyCopy
      }
      categoryId
      filterId
      filterName
      filterType
      filters
    }
  }
`;
export const fragments = {
  metaDataEntry,
  metadataDraftEntry,
  metadataVersionDetails,
  upload,
  localeDetails,
  metaDataEntryOptimized,
  uploadComparison,
  metaDataEntryById,
};
