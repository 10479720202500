import * as React from 'react';
import { any, isEmpty } from 'ramda';
import Table, { Column } from 'features/seo/UploadResult/Table/UploadTable';
import { Message } from 'types';
import * as Styled from './styles';

const columns: Column[] = [
  { key: 'id', label: 'ID' },
  {
    key: 'pageTitle',
    label: 'Page title',
  },
  {
    key: 'id',
    label: '',
    render: (val) => <Styled.Error>{`Entry with id ${val} will be removed`}</Styled.Error>,
  },
];

interface ErrorMessagesProps {
  deletedFiles: Message[];
}

export const DeletedFiles: React.FC<ErrorMessagesProps> = ({ deletedFiles }) => {
  const filteredDeletedFiles = deletedFiles.filter((m) => any((r) => r.includes('Entry will be removed'), m.messages));
  return isEmpty(filteredDeletedFiles) ? null : (
    <>
      <hr />
      <h4>Rows that will be deleted</h4>
      <Table columns={columns} data={deletedFiles} />
    </>
  );
};
