import styled from '@emotion/styled';
import { colourNeutral1, colourNeutral3, colourStaticBlack, colourTextAndIcon2 } from '@ingka/variables/colours-css';
import { fontSize50, space100, space125, space25, space75, thicknessThin } from '@ingka/variables';
import { InputTextField } from '../styles';

export const AutocompleteContainer = styled.div`
  width: 100%;
  position: relative;

  input {
    font-size: ${fontSize50};
  }
`;

export const AutoCompleteIconButton = styled.button`
  background: inherit;
  border: none;
  height: ${space125};
  width: ${space125};
  position: absolute;
  bottom: 18px;
  right: 14px;

  > svg {
    fill: ${colourTextAndIcon2};
    stroke: ${colourTextAndIcon2};
    stroke-width: 1px;
    height: ${space100};
    width: ${space100};
  }
`;

export const AutoCompleteInputWrapper = styled.div`
  position: relative;
`;

export const AutoCompleteInputField = styled(InputTextField)`
  margin-bottom: 0;

  & > div {
    padding-right: ${space25};
    padding-left: ${space25};
  }
`;

export const OptionsList = styled.ul`
  list-style: none;
  margin-top: -${space125};
  padding: 0;
  width: 100%;
  max-height: 300px;
  background-color: ${colourNeutral1};
  overflow-y: auto;
  border: ${thicknessThin} solid ${colourStaticBlack};
  border-top: none;
  position: absolute;
  z-index: 100;
`;

export const OptionItem = styled.li`
  padding: ${space75};
  cursor: pointer;
  font-size: ${fontSize50};

  &:hover {
    background-color: ${colourNeutral3};
  }
`;
