import styled from '@emotion/styled';
import { radiusM, shadowSizeDefault, space200 } from '@ingka/variables';

export const Wrapper = styled.div`
  position: relative;
`;

export const FloatingMenuContent = styled.div<{
  leftPosition?: string;
  topPosition?: string;
}>`
  position: absolute;
  border: 1px solid #ddd;
  left: ${(props) => (props.leftPosition ? props.leftPosition : '-150px')};
  top: ${(props) => (props.topPosition ? props.topPosition : space200)};
  z-index: 102;
  background-color: white;
  border-radius: ${radiusM};
  box-shadow: ${shadowSizeDefault};

  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  label {
    width: max-content;
  }
`;
