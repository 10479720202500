import React, { useState } from 'react';
import Button from '@ingka/button';
import Checkbox from '@ingka/checkbox';
import gear from '@ingka/ssr-icon/paths/gear';
import Text from '@ingka/text';
import * as Styled from 'components/CheckboxGroup/styles';
import { FloatingMenuWithActionButtons } from '../FloatingMenuWithActionButtons';

export type CheckboxFields = {
  checked: boolean;
  label: string;
  value: string;
  id: string;
};

type CheckboxGroupProps = {
  onApply: (visibleColumns: string[]) => void;
  defaultCheckboxes: CheckboxFields[];
  defaultColumns: CheckboxFields[];
  hasToggleView?: boolean;
};
export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ onApply, defaultCheckboxes, defaultColumns, hasToggleView = false }) => {
  const defaultCheckedCheckBoxesIds = defaultCheckboxes.filter((c) => c.checked).map((c) => c.id);
  const allCheckBoxesIds = defaultCheckboxes.map((c) => c.id);
  const defaultColumnIds = defaultColumns.filter((c) => c.checked).map((c) => c.id);
  const [checkboxes, setCheckboxes] = useState<string[]>(defaultCheckedCheckBoxesIds);
  const countTotalCheckboxes = allCheckBoxesIds.length;
  const countCheckedCheckboxes = checkboxes.length;
  const areNoCheckboxesChecked = countCheckedCheckboxes === 0;
  const areAllCheckboxesChecked = countCheckedCheckboxes === countTotalCheckboxes;
  const areSomeCheckboxesChecked = !areNoCheckboxesChecked && !areAllCheckboxesChecked;
  const resetToDefaultCheckboxes = () => setCheckboxes(defaultColumnIds);
  const onCancel = () => setCheckboxes(defaultCheckedCheckBoxesIds);

  const toggleSelectAll = () => {
    if (areAllCheckboxesChecked) {
      setCheckboxes([]);
    } else {
      setCheckboxes(allCheckBoxesIds);
    }
  };

  const toggleCheckbox = (id: string) => {
    if (checkboxes.includes(id)) {
      setCheckboxes((prevCheckboxIds) => prevCheckboxIds.filter((checkbox) => checkbox !== id));
    } else {
      setCheckboxes((prevCheckboxIds) => [...prevCheckboxIds, id]);
    }
  };
  const isChecked = (id: string) => checkboxes.includes(id);
  const floatingLeftPosition = hasToggleView ? '-190px' : '-290px';

  return (
    <FloatingMenuWithActionButtons
      onCancel={onCancel}
      onApply={() => onApply(checkboxes)}
      onClickReset={resetToDefaultCheckboxes}
      isapplyDisabled={areNoCheckboxesChecked}
      floatingLeftPosition={floatingLeftPosition}
      floatingTopPosition={'50px'}
      toggleButton={(toggleOpen) => (
        <Button
          data-testid={'floating-menu'}
          size={'small'}
          text={'Columns'}
          iconPosition={'leading'}
          type={'tertiary'}
          ssrIcon={gear}
          onClick={toggleOpen}
        />
      )}
    >
      <Styled.CheckboxGroup>
        <Text tagName="p" bodySize="s" data-testid={'selected-checkboxes-count'}>
          {countCheckedCheckboxes} out of {countTotalCheckboxes} selected
        </Text>
        <Checkbox
          id={'selectAll'}
          value={'selectAll'}
          label={'Select all'}
          checked={areAllCheckboxesChecked}
          indeterminate={areSomeCheckboxesChecked}
          onChange={toggleSelectAll}
        />
        <Styled.CheckboxGrid>
          {defaultCheckboxes.map(({ id, label }) => (
            <Checkbox
              name={label}
              key={id}
              label={label}
              id={`checkbox-${id}`}
              value={id}
              checked={isChecked(id)}
              onChange={() => toggleCheckbox(id)}
            />
          ))}
        </Styled.CheckboxGrid>
      </Styled.CheckboxGroup>
    </FloatingMenuWithActionButtons>
  );
};
