import styled from '@emotion/styled';
import { fontSize75, space100, space50 } from '@ingka/variables';
import Pill from '@ingka/pill';
import { colourNeutral3 } from '@ingka/variables/colours-css';

export const Menu = styled.div`
  width: 200px;
`;

export const MenuItem = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${space50};
  padding: ${space50} ${space100};
  border: none;
  background: ${({ disabled }) => (disabled ? colourNeutral3 : 'inherit')};

  font-size: ${fontSize75};

  &:hover {
    background: ${({ disabled }) => (disabled ? colourNeutral3 : '#eee')};
  }
`;

export const PillButton = styled(Pill)`
  background-color: transparent;
`;
