import React from 'react';
import Pill from '@ingka/pill';
import feedbackDissatisfied from '@ingka/ssr-icon/paths/feedback-dissatisfied';
import feedbackSad from '@ingka/ssr-icon/paths/feedback-sad';
import feedbackNeutral from '@ingka/ssr-icon/paths/feedback-neutral';
import feedbackSatisfied from '@ingka/ssr-icon/paths/feedback-satisfied';
import feedbackHappy from '@ingka/ssr-icon/paths/feedback-happy';
import * as Styled from './styles';
import { FlexContainer } from 'components/FlexContainer';

type SurveyRatingProps = {
  rating?: number;
  onChange: (newRating: number) => void;
};

export const SurveyRating: React.FC<SurveyRatingProps> = ({ rating, onChange }) => {
  const surveyRatingOptions = [feedbackDissatisfied, feedbackSad, feedbackNeutral, feedbackSatisfied, feedbackHappy];

  return (
    <Styled.SurveyRatingContainer>
      <FlexContainer justify="space-between">
        {surveyRatingOptions.map((ratingOption, index) => (
          <Pill
            key={index}
            data-testid={`survey-rating-${index}`}
            selected={rating === index}
            onClick={() => onChange(index)}
            iconOnly
            size="medium"
            label={`Pill ${index}`}
            ssrIcon={ratingOption}
          />
        ))}
      </FlexContainer>
      <Styled.SurveyRatingTip>
        <FlexContainer justify="space-between">
          <span>Very dissatisfied</span>
          <span>Very satisfied</span>
        </FlexContainer>
      </Styled.SurveyRatingTip>
    </Styled.SurveyRatingContainer>
  );
};
