import React, { FC } from 'react';
import Select from 'components/Select';
import { getCountryName } from 'opt-util/helpers';
import { useRedirectRequests } from 'hooks/contexts/RedirectRequestsContext';

export const CountryDropdown: FC = () => {
  const { setSelectedCountry, selectedCountry, allLocales, isLoading } = useRedirectRequests();
  if (!allLocales) return null;
  return (
    <Select
      id="countrySelector"
      data-testid={'country-select'}
      disabled={isLoading}
      width={'fit-content'}
      onChange={(e) =>
        setSelectedCountry({
          countryName: getCountryName(e.target.value),
          countryCode: e.target.value,
        })
      }
      value={selectedCountry.countryCode}
    >
      {allLocales.map((country) => (
        <Select.Option value={country} label={`${getCountryName(country)}, ${country.toUpperCase()}`} key={country} />
      ))}
    </Select>
  );
};
