import React, { useState } from 'react';
import Tabs, { Tab } from '@ingka/tabs';
import Container from 'components/Container';
import { useCountry } from 'hooks/useCountry';
import { useLatestByCountryQuery } from '__generated__/types';
import * as Styled from './styles';
import Text from '@ingka/text';
import { FlexContainer } from '../../components/FlexContainer';
import { RuleInvocations } from '../../features/Dashboard/RuleInvocations';
import { formatVersion, getSelectableLanguages } from '../../utils/version';
import { useLanguageSelection } from '../../hooks/contexts/LanguageContext';
import { LanguageDropdown } from '../../components/LanguageDropdown';
import { RedirectRequests } from '../../features/Dashboard/RedirectRequests';
import { Features, useFeatureContext } from '../../hooks/contexts/FeatureContext';
import { RedirectRequestProvider } from '../../hooks/contexts/RedirectRequestsContext';
import { Loader } from '../../components/Loader';

export const Dashboard: React.FC = () => {
  const [country] = useCountry();
  const [activeRedirectUsageTabKey, setActiveRedirectUsageTabKey] = useState('high');
  const countryCode = country?.countryCode;
  const { selectedLanguage } = useLanguageSelection();
  const { data, loading } = useLatestByCountryQuery({
    variables: { country: countryCode as string },
    skip: !countryCode,
  });
  const versionsForCountry = data?.latestVersionsForCountry;
  const formattedVersions = versionsForCountry?.map(formatVersion) ?? [];
  const selectableLanguages = getSelectableLanguages(formattedVersions, countryCode ? countryCode : '');
  const multiplePolicies = selectableLanguages.map(({ languageCode }) => `${countryCode}-${languageCode}`);
  const ruleUsageTabs = [
    { type: 'high', name: 'High' },
    { type: 'low', name: 'Low' },
  ];
  const { featureIsActive } = useFeatureContext();
  const isRedirectRequestsActive = featureIsActive(Features.REDIRECT_REQUESTS);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Styled.DashboardSectionWrapper direction={'column'} mt={1}>
        {isRedirectRequestsActive && (
          <RedirectRequestProvider>
            <RedirectRequests />
          </RedirectRequestProvider>
        )}
        <Styled.DashboardSection direction="column">
          <FlexContainer direction={'row'} justify={'space-between'} align-items={'center'}>
            <Text tagName={'h4'} headingSize={'xs'}>
              Redirect Usage {selectedLanguage !== '*' && `- ${selectedLanguage.toUpperCase()}`}
            </Text>
            <LanguageDropdown countryCode={countryCode ?? ''} refreshRedirect={true} />
          </FlexContainer>
          <FlexContainer direction={'column'} mt={0.5}>
            <Tabs
              tabs={ruleUsageTabs.map(({ type, name }) => (
                <Tab key={type} tabPanelId={type} text={name} />
              ))}
              tabPanels={
                <RuleInvocations
                  policy={selectedLanguage}
                  direction={activeRedirectUsageTabKey === 'high' ? 'desc' : 'asc'}
                  multiplePolicies={selectedLanguage === '*' ? multiplePolicies : undefined}
                />
              }
              defaultActiveTab={activeRedirectUsageTabKey}
              onTabChanged={(selectedKey: string) => setActiveRedirectUsageTabKey(selectedKey)}
            />
          </FlexContainer>
        </Styled.DashboardSection>
      </Styled.DashboardSectionWrapper>
    </Container>
  );
};
