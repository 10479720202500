import styled from '@emotion/styled';
import Loading from '@ingka/loading';
import RadioButtonGroup from '@ingka/radio-button-group';
import { Prompt } from '@ingka/modal';
import { FlexContainer } from 'components/FlexContainer';

export const TargetStatusContainer = styled.div`
  width: max-content;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export const TargetStatusMessage = styled.div`
  height: 20px;
  margin-bottom: 20px;
`;

export const TargetStatusMessageLoader = styled(Loading)`
  padding-top: 10px;
`;
export const PromptWithWidth = styled(Prompt)`
  .prompt__content-wrapper {
    min-width: 50rem;
    max-width: fit-content;
  }

  .input-field--error.input-field {
    margin-bottom: 8px;
  }
`;

export const FlexWithWidth = styled(FlexContainer)<{ mt?: number }>`
  margin-top: ${({ mt }) => `${mt}px`};

  .form-field {
    width: 100%;
  }
`;

export const RuleModalHeader = styled.div`
  display: flex;
  gap: 12px;
`;

export const RadioButtonGroupPosition = styled(RadioButtonGroup)`
  legend {
    padding-top: 12px;
  }

  .radio:not(:last-child),
  .radio {
    margin-bottom: 8px;
  }
`;
