import React, { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { DEFAULT_DATE_RANGE, useRedirectRequests } from '../../../hooks/contexts/RedirectRequestsContext';
import { FloatingMenuWithActionButtons } from '../../../components/FloatingMenuWithActionButtons';
import Select from '../../../components/Select';
import { DateInputField } from '../../../components/DateInputField';
import { validateValueWithType, ValidationTypes } from '../../../utils/validations';
import { datePickerOptions } from './datePickerOptions';

import * as Styled from './styles';
import SSRIcon from '@ingka/ssr-icon';
import chevronDownSmall from '@ingka/ssr-icon/paths/chevron-down-small';

const validateStartEndDates = (startDate: string, endDate: string) => {
  if (startDate > endDate) {
    return 'Start Date must be earlier than end date';
  }
  return '';
};

export const DatePicker: FC = () => {
  const { selectedDateRange, setSelectedDateRange } = useRedirectRequests();
  const { current: today } = useRef(new Date());
  const [dateRange, setDateRange] = useState<typeof selectedDateRange>(selectedDateRange);
  const errorMessage = useMemo(() => {
    return {
      startDate: validateValueWithType(dateRange.startDate, ValidationTypes.date) || validateStartEndDates(dateRange.startDate, dateRange.endDate),
      endDate: validateValueWithType(dateRange.endDate, ValidationTypes.date),
    };
  }, [dateRange.startDate, dateRange.endDate]);

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = datePickerOptions.find((i) => i.value === e.target.value);
    if (!selectedOption) return;
    setDateRange({
      startDate: selectedOption.calcStartDate(today).toISOString().split('T')[0],
      endDate: selectedOption.calcEndDate(today).toISOString().split('T')[0],
      timeRange: {
        value: selectedOption.value,
        label: selectedOption.label,
      },
    });
  };

  const onChangeDateInput = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    setDateRange((prev) => ({
      ...prev,
      [field]: e.target.value.trim(),
      timeRange: {
        value: 'custom',
        label: 'Custom',
      },
    }));
  };

  return (
    <FloatingMenuWithActionButtons
      floatingLeftPosition={'-293px'}
      floatingTopPosition={'50px'}
      onCancel={() => setDateRange(selectedDateRange)}
      onApply={() => setSelectedDateRange(dateRange)}
      onClickReset={() => {
        setDateRange(DEFAULT_DATE_RANGE);
      }}
      toggleButton={(toggleOpen) => (
        <Styled.DatepickerInput data-testid={'date-range-button'} onClick={toggleOpen}>
          {selectedDateRange.timeRange.label}
          <SSRIcon paths={chevronDownSmall} />
        </Styled.DatepickerInput>
      )}
    >
      <Styled.DateInputsWrapper>
        <Select id={'time-range'} value={dateRange.timeRange.value} onChange={onChangeSelect} width={'100%'} data-testid={'date-range-select'}>
          {datePickerOptions.map(({ label, value }) => (
            <Select.Option value={value} label={label} key={value} />
          ))}
        </Select>
      </Styled.DateInputsWrapper>
      <Styled.DateInputsWrapper>
        <DateInputField
          data-testId={'date-range-startDate-input'}
          id={'startDate'}
          label={'From'}
          onChange={(e) => {
            onChangeDateInput(e, 'startDate');
          }}
          value={dateRange.startDate}
          errorMessage={errorMessage.startDate}
          shouldValidate={dateRange.startDate.length >= 1}
        />
        <DateInputField
          data-testId={'date-range-endDate-input'}
          id={'endDate'}
          label={'To'}
          value={dateRange.endDate}
          errorMessage={errorMessage.endDate}
          shouldValidate={dateRange.endDate.length >= 1}
          onChange={(e) => {
            onChangeDateInput(e, 'endDate');
          }}
        />
      </Styled.DateInputsWrapper>
    </FloatingMenuWithActionButtons>
  );
};
