import { FC, useContext, useEffect, useState } from 'react';
import { FlexContainer } from '../../../components/FlexContainer';

import { Context as LoginContext } from '../../../hooks/contexts/LoginContext';

import { getMost404Pages, Most404PagesResponse } from '../../../api/redirectRequests';
import { getCountryName } from '../../../opt-util/helpers';
import Text from '@ingka/text';
import { useRedirectRequests } from '../../../hooks/contexts/RedirectRequestsContext';
import * as Styled from './styles';

interface Most404PagesRow {
  country: string;
  count: number;
}

export const Most404pages: FC = () => {
  const { selectedDateRange } = useRedirectRequests();
  const { refreshToken } = useContext(LoginContext);
  const [pagesData, set404Pages] = useState<Most404PagesRow[]>([]);

  useEffect(() => {
    refreshToken().then((token: string) => {
      getMost404Pages(token, {
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
      }).then((data: Most404PagesResponse) => {
        set404Pages(
          data.rows.map((row) => ({
            country: `${getCountryName(row.country)}, ${row.country.toUpperCase()}`,
            count: row.count,
          })),
        );
      });
    });
  }, [selectedDateRange.startDate, selectedDateRange.endDate]);

  return (
    <Styled.Most404sContainer>
      <Text tagName={'h4'} headingSize={'xs'}>
        Most 404 pages
      </Text>

      <Styled.Most404PagesRows>
        {pagesData.map((item, index) => (
          <FlexContainer justify={'space-between'} key={`cell-${index}`} data-testid={`data-${index}`}>
            <Text bodySize={'s'}>{item.country}</Text>
            <Text bodySize={'s'}>{item.count}</Text>
          </FlexContainer>
        ))}
      </Styled.Most404PagesRows>
    </Styled.Most404sContainer>
  );
};
