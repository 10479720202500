import React from 'react';
import * as Styled from './styles';
import Button from '@ingka/button';
import { range } from 'ramda';
import { Paginator } from 'hooks/usePaginator';

interface PaginationProps {
  paginator: Paginator;
}

export const Pagination: React.FC<PaginationProps> = ({ paginator }) => {
  const { page, lastPage, paginatePreviousPage, paginateNextPage, paginatePage } = paginator;
  const start = Math.max(1, Math.min(page - 5, lastPage - 10));
  const end = Math.min(lastPage + 1, start + 11);
  const isSelected = (pageNumber: number) => pageNumber === page;
  const shouldDisplayPaginationButtons = lastPage > 1;
  return (
    <>
      {shouldDisplayPaginationButtons && (
        <Styled.PageNumbers data-testid={'pagination'}>
          <Button onClick={paginatePreviousPage} text={'<<'} />
          {range(start, end).map((page) => (
            <Styled.Page
              key={`page-${page}`}
              selected={isSelected(page)}
              data-testid={isSelected(page) ? 'selected' : undefined}
              onClick={() => paginatePage(page)}
            >
              {page}
            </Styled.Page>
          ))}
          <Button onClick={paginateNextPage} text={'>>'} />
        </Styled.PageNumbers>
      )}
      <Styled.Summary aria-labelledby="summary-pagination" data-testid="summary-pagination">
        {page} of {lastPage}
      </Styled.Summary>
    </>
  );
};
