import styled from '@emotion/styled';

export const ErrorMessageListItem = styled.li`
  color: red;
  font-size: 0.8rem;
`;

export const WarningMessage = styled.li`
  color: darkorange;
  font-size: 0.8rem;
`;
