import styled from '@emotion/styled';
import { space50 } from '@ingka/variables';

export const SurveyRatingContainer = styled.div`
  padding: ${space50} 0;
`;

export const SurveyRatingTip = styled.div`
  margin-top: ${space50};
`;
