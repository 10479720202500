import React, { FC, ReactElement } from 'react';
import { FlexContainer } from 'components/FlexContainer';
import * as Styled from '../styles';

export const KeyValue: FC<{
  label: string;
  value?: string | ReactElement | boolean | null;
  labelSize?: number;
}> = (props) => {
  return props.value ? (
    <FlexContainer justify="flex-start">
      <Styled.Label flexSize={props.labelSize}>{props.label}</Styled.Label>
      <Styled.Value>{props.value}</Styled.Value>
    </FlexContainer>
  ) : null;
};
