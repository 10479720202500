import { ChartBarData } from '../../../components/BarChart';
import { DailyRequestsCountMap } from '../../../api/redirectRequests';

export const graphOptions = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      position: 'bottom' as const,
      text: '404 Page',
    },
    tooltip: {
      callbacks: {
        label: function (data: { formattedValue: string }) {
          return '404 Pages:' + data.formattedValue;
        },
      },
    },
  },
};

export const formatDataForGraph = (data: DailyRequestsCountMap, status: number): ChartBarData => {
  return {
    labels: Object.keys(data)?.map((item) => item),
    datasets: [
      {
        label: `${status} Pages`,
        data: Object.keys(data)?.map((item) => data[item as keyof DailyRequestsCountMap]?.[`statusResult${status}`]?.total),
        backgroundColor: '#0058A3',
      },
    ],
  };
};
