import React, { ChangeEvent, forwardRef, Ref, SyntheticEvent, useRef } from 'react';
import FormField from '@ingka/form-field';
import * as Styled from './styles';

interface DateFieldProps {
  disabled?: boolean;
  id: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

interface DateInputFieldProps extends DateFieldProps {
  shouldValidate: boolean;
  errorMessage?: string;
  onClick?: (event: SyntheticEvent) => void;
  fieldHelper?: string;
}

interface HTMLDateInputElement extends HTMLInputElement {
  showPicker: () => void;
}

const DatePicker = forwardRef<HTMLInputElement, DateInputFieldProps>((props, ref) => {
  const { id, label, value, disabled = false, onChange, shouldValidate, errorMessage, onClick } = props;
  const valid = !errorMessage;
  const validation = (shouldValidate && !valid && 'error') || (shouldValidate && valid && `success`) || 'default';
  return (
    <Styled.DatePickerWrapper isDisabled={disabled}>
      <label htmlFor={id} title={label}>
        {label}
      </label>
      <Styled.DatePickerInput
        data-testid={id}
        name={id}
        type="date"
        value={value}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
        onChange={onChange}
        validation={validation}
      />
    </Styled.DatePickerWrapper>
  );
});

export const DateInputField: React.FC<DateInputFieldProps> = ({ id, disabled, label, shouldValidate, errorMessage, value, onChange }) => {
  const interactiveRef = useRef<HTMLDateInputElement>(null);
  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    interactiveRef.current?.showPicker && interactiveRef.current?.showPicker();
  };
  return (
    <FormField
      validation={{
        msg: errorMessage,
        id: `${id}-error-message`,
      }}
      valid={!errorMessage}
      shouldValidate={shouldValidate}
      disabled={disabled}
    >
      <DatePicker
        onClick={handleClick}
        disabled={disabled}
        id={id}
        label={label}
        onChange={onChange}
        ref={interactiveRef as unknown as Ref<HTMLInputElement> | undefined}
        value={value}
        shouldValidate={shouldValidate}
        errorMessage={errorMessage}
      />
    </FormField>
  );
};
