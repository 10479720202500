import { GaxiosResponse, request } from 'gaxios';

interface StatusResult {
  [key: string]: {
    total: number;
  };
}

export interface DailyRequestsCountMap {
  [key: string]: StatusResult;
}

export interface RedirectRequestResponseData {
  countryCode: string;
  dailyRequestsCount: DailyRequestsCountMap;
  totalRequests?: number;
}

interface GetRedirectRequestsParams {
  startDate: string;
  endDate: string;
  statusCode: number;
  countryCode: string;
  token: string;
}

export const getRedirectRequests = async ({
  startDate,
  endDate,
  statusCode,
  countryCode,
  token,
}: GetRedirectRequestsParams): Promise<Array<RedirectRequestResponseData>> => {
  const response: GaxiosResponse = await request({
    method: 'GET',
    url: `/api/redirect/redirectRequests/${countryCode}`,
    headers: { Authorization: `Bearer ${token}` },
    params: { startDate, endDate, statusCode },
  });
  return response.data?.data;
};

export interface Most404PagesResponse {
  rows: [{ country: string; count: number }];
}

interface Most404pagesParams {
  startDate: string;
  endDate: string;
}

export const getMost404Pages = async (token: string, params: Most404pagesParams): Promise<Most404PagesResponse> => {
  const response: GaxiosResponse = await request({
    method: 'GET',
    url: '/api/redirect/redirectRequests/most404Pages',
    headers: { Authorization: `Bearer ${token}` },
    params,
  });
  return response.data as Most404PagesResponse;
};

export interface Total404PagesResponse {
  total: number;
}

export interface Total404PagesParams {
  country: string;
  startDate?: string;
  endDate?: string;
}

export const getTotal404Pages = async (token: string, params: Total404PagesParams): Promise<Total404PagesResponse> => {
  const response: GaxiosResponse = await request({
    method: 'GET',
    url: `/api/redirect/redirectRequests/total404Pages/${params.country}`,
    params: { startDate: params.startDate, endDate: params.endDate },
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data as Total404PagesResponse;
};
