import React, { JSX, useContext } from 'react';
import { FileUploadForm } from 'features/seo/FileUploadForm';
import * as Styled from './styles';
import { MenuItem, MenuList } from 'features/seo/styles';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

const postFile = async (file: File, refreshToken: () => Promise<string>) => {
  const data = new FormData();
  data.append('file', file);
  const token: string = await refreshToken();

  return await fetch(`/api/optimera/masterkeywords/upload`, {
    method: 'POST',
    body: data,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export interface UploadResult {
  rows: number;
  status: string;
  entriesWithoutMatch: [{ id: string; name: string }];
  entriesUpdated: [{ id: string; name: string }];
}

const CustomMessage: React.FC = () => (
  <div>
    <h3>Upload Keywords CSV File</h3>
    <p>
      The uploaded CSV file will update the entries in Country Zero with the given Primary, Secondary and Alternative Keywords.
      <br />
      The keywords will then be displayed in all countries as Suggested Keywords for each entry.
      <br />
      <br />
    </p>
  </div>
);

const customResult = (response: UploadResult): JSX.Element => (
  <Styled.ResultContainer>
    <Styled.ResultColumn>
      <h4>
        Updated rows: <strong data-testid={'updated-rows'}>{response.entriesUpdated.length}</strong>
      </h4>
      <MenuList>
        {response.entriesUpdated.map((e) => (
          <MenuItem key={e.id}>
            {e.id} - {e.name}
          </MenuItem>
        ))}
      </MenuList>
    </Styled.ResultColumn>

    <Styled.ResultColumn>
      <h4>
        Entries without match: <strong data-testid={'entries-without-match'}>{response.entriesWithoutMatch.length}</strong>
      </h4>
      <MenuList>
        {response.entriesWithoutMatch.map((e) => (
          <MenuItem key={e.id}>
            {e.id} - {e.name}
          </MenuItem>
        ))}
      </MenuList>
    </Styled.ResultColumn>
  </Styled.ResultContainer>
);

export const Keywords: React.FC = () => {
  const { refreshToken } = useContext(LoginContext);
  return <FileUploadForm customMessage={<CustomMessage />} customResult={customResult} postFile={(file) => postFile(file, refreshToken)} />;
};
