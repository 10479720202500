import styled from '@emotion/styled';
import Text from '@ingka/text';

export const CommentText = styled(Text)`
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const NameText = styled(Text)`
  white-space: nowrap;
`;
