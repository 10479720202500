import styled from '@emotion/styled';
import InlineMessage from '@ingka/inline-message';
import Pill from '@ingka/pill';
import IngkaTable from '@ingka/table';
import Text from '@ingka/text';
import { fontSize75, fontWeightBold, space125, space25, space50, space75 } from '@ingka/variables/design-tokens';
import { FlexContainer } from 'components/FlexContainer';

export const Badge = styled.div<{ backgroundColor?: string; color: string }>`
  padding: ${space25} ${space50};
  font-size: 10px;
  font-weight: bold;
  border-radius: ${space50};
  border: 1px solid ${(props) => props.color || 'gray'};

  flex: 0 1 auto;
  align-self: flex-start;
  color: ${(props) => props.color || 'white'};

  float: left;
  margin: ${space25} ${space25} ${space25} 0;
`;
