import styled from '@emotion/styled';
import { Tab, TabPanel } from '@ingka/tabs';
import { fontSize50 } from '@ingka/variables';

export const ModalSideBarTab = styled(Tab)`
  font-size: ${fontSize50};
  font-weight: normal;
`;

export const ModalSideBarTabPanel = styled(TabPanel)`
  padding: 0;
`;

export const LinkWithWordBreak = styled.a`
  word-break: break-word;
`;
