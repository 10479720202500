import React from 'react';
import { TabPanel } from '@ingka/tabs';
import { StatusOverviewRedirectCard } from '../StatusOverviewRedirectCard';
import { VersionOverview } from '../../../types';
import { StatusOverviewMetadataCard } from '../StatusOverviewMetadataCard';

type StatusOverviewPanelProps = {
  activeTab: string;
  metadataPanelProps?: { formattedVersions: VersionOverview[] };
};

export const StatusOverviewPanel: React.FC<StatusOverviewPanelProps> = ({ activeTab, metadataPanelProps }) => {
  const redirectTabs = ['redirect', 'shorturl'];
  return (
    <TabPanel key={activeTab} tabPanelId={activeTab} style={{ padding: 0 }}>
      {redirectTabs.includes(activeTab) ? (
        <StatusOverviewRedirectCard activeTab={activeTab} />
      ) : (
        <StatusOverviewMetadataCard
          activeTab={activeTab}
          {...(metadataPanelProps as {
            formattedVersions: VersionOverview[];
          })}
        />
      )}
    </TabPanel>
  );
};
