import styled from '@emotion/styled';
import { colourSemanticCaution, colourSemanticNegative, colourSemanticPositive } from '@ingka/variables/colours-css';

export const TargetStatusSuccess = styled.div`
  display: flex;
  color: ${colourSemanticPositive};
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const TargetStatusError = styled.div`
  display: flex;
  color: ${colourSemanticNegative};
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const TargetStatusWarning = styled.div`
  display: flex;
  color: ${colourSemanticCaution};
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const TargetStatusNone = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;
