import { createContext, ReactNode, useContext, useState } from 'react';

interface ToastContextType {
  toastContent: ToastContent;
  setToastContent: ({ type }: ToastContent) => void;
}

interface ToastContent {
  type: ToastEventTypes | null;
}

export enum ToastEventTypes {
  FILE_DOWNLOAD = 'download',
  SAVED = 'saved',
  SAVE_FAILED = 'save_failed',
}

export const defaultToastContent: ToastContent = {
  type: null,
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export function ToastProvider({ children }: { children: ReactNode }) {
  const [toastContent, setToastContent] = useState(defaultToastContent);

  return (
    <ToastContext
      value={{
        toastContent,
        setToastContent,
      }}
    >
      {children}
    </ToastContext>
  );
}

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
