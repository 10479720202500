import styled from '@emotion/styled';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  padding: 1rem;

  &:nth-of-type(odd) {
    background-color: #f8f8f8;
  }
`;
export const Entry = styled.div`
  display: flex;
  flex-direction: row;
`;
export const VerticalAlign = styled(Entry)`
  flex-direction: column;
`;
export const Original = styled(Entry)`
  align-items: center;
  color: #700;
`;
export const Updated = styled(Original)`
  color: #070;
`;
export const Pill = styled.span`
  padding: 0.5em;
  margin-right: 1em;
  color: green;
  border: 1px solid green;
  border-radius: 15px;
  font-size: 0.7rem;
`;
