import { request } from 'gaxios';
import { RuleType } from 'features/Rules/index';
import { useRequestWithTokenAndTransformer } from 'hooks/useWithToken';
import { RuleTypeDiff, UploadedRules } from '../../pages/rules/UploadRulesDiff/UploadRulesDiff';
import { FilterInputRules } from '../../hooks/contexts/FilterContext';

type RuleServiceParamsType = {
  refetchAfter: string;
  policy: string;
  pageLimit?: number;
  page?: number;
  searchValue?: string;
  sort?: string;
  multiplePolicies?: string[];
} & FilterInputRules;

type RuleParamsType = {
  pageLimit?: number;
  page?: number;
  search?: string;
  sort?: string;
  multiplePolicies?: string;
  startDate?: string;
  endDate?: string;
} & Omit<FilterInputRules, 'startDateUsage' | 'endDateUsage'>;

type RulesResponse = {
  rules: RuleType[];
  totalRules: number;
  pageLimit: number;
  page: number;
  searchValue?: string;
  multiplePolicies?: string[];
};

const createParamsObject = (params: RuleServiceParamsType): RuleParamsType => {
  const { searchValue, pageLimit, page, sort, multiplePolicies, startDateUsage, endDateUsage, targetStatus, invocations } = params;
  return {
    search: searchValue,
    pageLimit,
    page,
    sort,
    multiplePolicies: JSON.stringify(multiplePolicies),
    targetStatus,
    invocations,
    startDate: startDateUsage,
    endDate: endDateUsage,
  };
};

export const useFetchRules = (params: RuleServiceParamsType) => {
  const { refetchAfter, searchValue, policy, pageLimit, page, sort, targetStatus, invocations, startDateUsage, endDateUsage } = params;
  const paramsObject = createParamsObject(params);
  const { data, loading } = useRequestWithTokenAndTransformer<RulesResponse, RulesResponse>(
    {
      url: `/api/redirect/rules/${policy}`,
      params: paramsObject,
    },
    [pageLimit, page, searchValue, refetchAfter, sort, targetStatus, invocations, startDateUsage, endDateUsage],
    ({ rules, totalRules, pageLimit, page }) => ({
      rules,
      totalRules,
      pageLimit,
      page,
    }),
  );
  return { data, loading };
};

export const fetchUploadedRulesByIds = async (
  policy: string,
  id: string,
  docIds: string[],
  token: string,
): Promise<{
  uploadedRules: RuleTypeDiff[];
}> => {
  const response =
    docIds.length > 0 &&
    (await request({
      url: `/api/redirect/rules/${policy}/upload-diff/${id}`,
      method: 'POST',
      data: { docIds: docIds.slice(0, 30) },
      headers: { Authorization: `Bearer ${token}` },
    }));
  if (!response) {
    throw new Error('No ids provided!');
  }
  return { uploadedRules: (response.data as UploadedRules).uploadedRules as RuleTypeDiff[] };
};
