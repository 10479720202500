import { useContext, useEffect } from 'react';
import { Context as EditorContext, FormKeys } from './contexts/EditorContext';
import { environment, ValidationOptions } from '../environment';
import { defaultTo, prop } from 'ramda';
import { DUPLICATE_SLUGS_QUERY } from '../features/seo/queries';
import { useApolloClient } from './useApolloClient';
import { OmitTN } from '../opt-util/types';
import { MetaDataCompleteFragment } from '../__generated__/types';

type ValidationFn = (
  input: string | undefined,
  country?: string,
  id?: string,
  type?: string,
  language?: string,
) => string | Promise<string | undefined> | undefined;
export type AllKeys = keyof Omit<NormalizedEntry, 'isOptimized'>;
const urlSlugMatcher = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
export type NormalizedEntry = OmitTN<MetaDataCompleteFragment> & {
  author?: string | null | undefined;
  ts?: number | undefined | null;
};

export const useValidation = (
  fieldId: FormKeys,
  value: string | undefined,
): {
  errorMessage: string | undefined;
} => {
  const { state, setError } = useContext(EditorContext);
  const client = useApolloClient();

  const getValidationFunction = (propName: AllKeys): ValidationFn => {
    const validation = environment.validation;
    const validationOpts = defaultTo<ValidationOptions, ValidationOptions>({}, prop(propName, validation));
    return async (value: string | undefined, country, id, type, language) => {
      const str = value || '';
      if (type === 'filter' && ['description', 'ogDescription'].includes(propName)) return;
      if (type === 'filter' && !['robots', 'ogImage', 'keywords', 'filterType', 'filterName'].includes(propName) && !str) {
        return 'Required';
      }
      if (propName === 'urlSlug') {
        if (!str) {
          return 'URL slug cannot be empty. Please provide a valid URL slug.';
        }
        if (!str.match(urlSlugMatcher)) {
          return 'URL Slug must only use alphanumeric characters and hyphen (e.g. "valid-url-slug")';
        }
        if (type === 'PIP') return;
        const { data } = await client.query({
          query: DUPLICATE_SLUGS_QUERY,
          variables: { country, language, type, urlSlug: str },
          fetchPolicy: 'no-cache',
        });
        const entries = data.currentVersion.entriesWithSameSlug as string[];
        const entriesOtherThanItSelf = entries.filter((entryId) => entryId !== id);
        if (entriesOtherThanItSelf.length > 0) {
          return `URL slug is already in use by: ${entriesOtherThanItSelf.toString()}`;
        }
      }
      if (validationOpts.min && str.length < validationOpts.min) {
        return `Minimum length for ${propName} is ${validationOpts.min} characters`;
      }
      if (validationOpts.max && str.length > validationOpts.max) {
        return `Maximum length for ${propName} is ${validationOpts.max} characters`;
      }

      if (str.match(/<\s*.*?>/)) {
        return `Invalid input for ${propName}. Should not contain <...>`;
      }
      if (propName === 'pageTitle') {
        const [, illegalChars] = str.match(/(www|http|(?<=[\s,.:;"']|^)(?=[\s,.:;"']|$))/i) ?? [];
        if (!illegalChars) return;
        return `Invalid input for ${propName}. Should not contain: ${illegalChars}`;
      }
    };
  };

  useEffect(() => {
    const validate = async () => {
      if (!state.versionDetails || !state.formData || fieldId === 'isOptimized' || fieldId === 'descriptionIsSynced' || fieldId === 'needsReview')
        return;
      const {
        type,
        market: { country, language },
      } = state.versionDetails;
      const validationFunction = getValidationFunction(fieldId);
      const error = await validationFunction(value, country, state.formData.id, type, language);
      if (state.errors[fieldId] !== error) {
        setError(fieldId, error);
      }
    };

    validate();
  }, [value]);

  return { errorMessage: state.errors[fieldId] };
};
