import styled from '@emotion/styled';
import { AccordionItem } from '@ingka/accordion';
import Table from '@ingka/table';

export const AccordionKeywordsItem = styled(AccordionItem)`
  .accordion-item-header {
    border-top: none;
    padding: 0;
    min-height: unset;
    justify-content: space-between;
  }

  .accordion-item-header__title-wrapper {
    flex-grow: 0;
  }

  .accordion__content {
    padding-top: 0;
  }
`;

export const FixedTable = styled(Table)`
  background-color: white;
  padding: 0.5rem;

  table {
    width: 100%;
    table-layout: fixed;
  }
`;
