import languages from 'iso-639-1';
import { LatestByCountryQuery } from '../__generated__/types';
import { VersionOverview } from '../types';

type VersionsForCountry = LatestByCountryQuery['latestVersionsForCountry'];
type VersionForCountry = VersionsForCountry[0];
type Language = Pick<VersionOverview, 'languageName' | 'languageCode' | 'countryCode'>;

export const formatVersion = (version: VersionForCountry): VersionOverview => ({
  id: version._id,
  type: version.type.toLowerCase(),
  languageCode: version.language,
  languageName: languages.getName(version.language),
  countryCode: version.country,
  size: version.size,
  needsReview: version.needsReview,
  optimizedItems: version.optimizedItems,
  addedLast14Days: version.addedLast14Days ?? 0,
});

export const getSelectableLanguages = (versions: VersionOverview[], countryCode: string): Language[] => {
  const uniqueLanguages = new Map<string, Language>();
  versions.forEach(({ languageCode, languageName }) => {
    if (!uniqueLanguages.has(languageCode)) {
      uniqueLanguages.set(languageCode, {
        languageCode,
        languageName,
        countryCode,
      });
    }
  });
  return Array.from(uniqueLanguages.values());
};
