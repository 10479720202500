import styled from '@emotion/styled';

export { TableColumnHeading } from '../styles';

export const TableHeading = styled('th')<{ width?: number }>`
  width: ${({ width }) => `${width}%`};

  :nth-of-type(n + 3):not(:nth-last-of-type(-n + 2)) {
    cursor: pointer;
  }

  div {
    .text {
      white-space: nowrap;
    }
  }
`;
