import React from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import Container from 'components/Container';
import LocaleHeader from 'components/LocaleHeader';
import { FlexContainer } from 'components/FlexContainer';
import { PoliciesTable } from 'features/PoliciesTable';
import * as Styled from 'features/PoliciesTable/styles';
import { useCountry } from 'hooks/useCountry';
import { useRequestWithTokenAndTransformer } from 'hooks/useWithToken';
import { Policy } from 'types';
import { PageHeaderWrapper } from '../styles';
import { StatusOverview } from '../../features/StatusOverview/StatusOverview';
import { LanguageDropdown } from '../../components/LanguageDropdown';
import { CountryFlag } from '../../components/CountryFlag';

const RedirectsPage: React.FC = () => {
  const [country] = useCountry();
  const { data, loading } = useRequestWithTokenAndTransformer<
    {
      policies: Policy[];
    },
    { policies: Policy[] }
  >({ url: `/api/redirect/policies/${country?.countryCode}` }, [], ({ policies }) => ({ policies }));
  const policies: Policy[] = data?.policies ?? [];
  const pageTypesMap = { redirect: 'Redirect', shorturl: 'Short URL' };
  const regular = {
    columnHeaders: ['Languages', 'Type', 'Modified', '', ''],
    policies: policies.filter((p) => !p.type),
  };
  const short = {
    columnHeaders: ['Domains', 'Type', 'Modified', '', ''],
    policies: policies.filter((p) => p.type === 'short'),
  };
  return (
    <Container page="redirects">
      <PageHeaderWrapper>
        <h2>Redirects</h2>
        <LanguageDropdown countryCode={country?.countryCode ?? ''} refreshRedirect={true} />
      </PageHeaderWrapper>
      {loading ? (
        <Loading>
          <LoadingBall />
        </Loading>
      ) : (
        <>
          <FlexContainer mt={1.5} mb={1} align={'center'}>
            {country && (
              <>
                <CountryFlag countryCode={country.countryCode} />
                <LocaleHeader country={country.countryCode} />
              </>
            )}
          </FlexContainer>
          <StatusOverview pageTypesMap={pageTypesMap} />

          <PoliciesTable columnHeaders={regular.columnHeaders} policies={regular.policies} />

          <Styled.ShortUrlHeader tagName="h3">Short Url</Styled.ShortUrlHeader>
          <PoliciesTable columnHeaders={short.columnHeaders} policies={short.policies} />
        </>
      )}
    </Container>
  );
};

export { RedirectsPage };
