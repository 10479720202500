import styled from '@emotion/styled';
import IngkaTable from '@ingka/table';
import { space125, space200, space300, space50 } from '@ingka/variables';
import { colourNeutral2, colourTextAndIcon1 } from '@ingka/variables/colours-css';

const metaDataListWidths = [20, 35, 25, 15, 5];
export const Table = styled(IngkaTable)`
  border: none;
  overflow: inherit !important;

  th {
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: none !important;

    ${metaDataListWidths.map(
      (width, index) => `
      &:nth-of-type(${index + 1}) {
        width: ${width}%;
      }
    `,
    )}
  }

  .text {
    color: ${colourTextAndIcon1} !important;
  }

  td {
    height: ${space300};
    padding: ${space50} !important;
    vertical-align: middle;
    word-break: break-word;
    border-bottom: none !important;

    ${metaDataListWidths.map(
      (width, index) => `
      &:nth-of-type(${index + 1}) {
        width: ${width}%;
      }
    `,
    )}
    &:nth-of-type(4) {
      text-align: end;
    }

    .btn__inner {
      height: ${space200};
      max-height: ${space200};
      min-height: ${space200};
    }
  }

  tr {
    :nth-of-type(odd) {
      background-color: ${colourNeutral2};
    }
  }
`;

export const PageWrapper = styled.div`
  margin-top: ${space125};
`;
