import React from 'react';
import { CountrySelectButton } from 'features/CountrySelectButton';
import logo from './ikea-logo.svg';
import * as Styled from './styles';
import { Logo } from './styles';

export const CountrySelectPage: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.Modal>
        <Styled.Message>
          <Logo src={logo} alt={'ikea logo'} />
          <Styled.Typography headingSize={'m'}>Optimera</Styled.Typography>
          <Styled.Typography>Welcome, Start by selecting your market.</Styled.Typography>
        </Styled.Message>
        <CountrySelectButton inverseTheme />
      </Styled.Modal>
    </Styled.Container>
  );
};
