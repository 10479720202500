import styled from '@emotion/styled';
import { FlexContainer } from 'components/FlexContainer';

export const TranslationButtonContainer = styled(FlexContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const LinkUrlActionButtonContainer = styled(FlexContainer)`
  width: 100%;
  padding: 0 0.7rem;
`;
