import React, { useCallback, useContext, useMemo } from 'react';
import LocaleHeader from 'components/LocaleHeader';
import { FlexContainer } from 'components/FlexContainer';
import { EditSheet } from 'features/MetaData/EditSheet';
import { Provider as EditorProvider } from 'hooks/contexts/EditorContext';
import { Context as MetadataContext } from 'hooks/contexts/MetaDataContext';
import { useLoader } from 'hooks/useLoader';
import { Column as MetaDataTableColumns } from 'types';
import { CurrentVersionQuery, FilterInput, MetaDataEntryInput, useEditItemMutation } from '__generated__/types';
import { MetadataItem } from '../MetaDataItem';
import { MetadataStats } from '../MetaDataStats';
import { MetaDataStrippedTable } from '../MetaDataStrippedTable';
import { toMetaData } from '../../../../opt-util/toGenericMetadata';
import { useHistory, useLocation } from 'react-router-dom';
import { isPublicera } from '../../../../opt-util/helpers';
import { ErrorComp } from '../../../../components/Error';

interface MetaDataEntryTableDataProps {
  isEditable: boolean;
  data: NonNullable<CurrentVersionQuery['currentVersion']>;
  displayListOrSquareView: number;
  refetchCallback: () => void;
  filter: FilterInput;
  columns: MetaDataTableColumns[];
}

export const MetaDataEntryTableData: React.FC<MetaDataEntryTableDataProps> = ({
  isEditable,
  data,
  refetchCallback,
  filter,
  displayListOrSquareView,
  columns,
}) => {
  const {
    state: { activeItemId: itemId },
  } = useContext(MetadataContext);
  const location = useLocation();
  const [, , country, language, type] = location.pathname.split('/');
  const entries = data.entries?.entries;
  const isPubliceraPage = isPublicera(data.type);
  const versionDetails = {
    type: data.type,
    urlSlugSyncEnabled: data.urlSlugSyncEnabled,
    versionId: data._id,
    market: { country: data.country, language: data.language },
  };
  const editorInitialState = useMemo(() => {
    const currentlyEditedEntry = entries.find((entry) => entry.id === itemId);
    if (!currentlyEditedEntry) return;
    const descriptionIsSynced = currentlyEditedEntry.description === currentlyEditedEntry.ogDescription;
    const isOptimized = !!currentlyEditedEntry.isOptimized;
    const needsReview = !!currentlyEditedEntry.needsReview;
    return {
      formData: {
        ...currentlyEditedEntry,
        descriptionIsSynced,
        isOptimized,
        needsReview,
      },
      hasErrors: false,
      errors: {},
      versionDetails,
      isOpen: true,
      itemId,
    };
  }, [entries, itemId]);
  const { go } = useHistory();
  const [, setLoading] = useLoader();
  const [editItem] = useEditItemMutation();
  const editItemCallback = useCallback(
    async (entry: MetaDataEntryInput) => {
      try {
        const item = toMetaData(entry);
        await editItem({
          variables: {
            country,
            language,
            type,
            entry: { ...item, pageTitle: item.pageTitle || '' },
            filter,
          },
        });
        setLoading(false);
        return refetchCallback();
      } catch (e) {
        console.log(e);
      }
    },
    [country, language, type, filter, refetchCallback],
  );
  const noData = entries.length <= 0;
  return (
    <FlexContainer direction="column">
      {!isPubliceraPage && (
        <div>
          <LocaleHeader country={data.country} language={data.language} />
          <MetadataStats totalItems={data.size} optimizedItems={data.optimizedItems} />
        </div>
      )}
      {noData && (
        <ErrorComp
          errorAction={() => go(0)}
          errorMessage={'We cannot find the item you are searching for'}
          errorTitle={'No data'}
          errorButtonText={'Retry'}
          hasPropsError={noData}
        />
      )}

      <div>
        {displayListOrSquareView === 1 &&
          entries.length > 0 &&
          entries.map((entry) => {
            return (
              <MetadataItem
                key={entry.id}
                data={entry}
                isEditable={isEditable}
                type={data.type}
                author={entry.author ?? 'unknown'}
                filter={filter}
                onDelete={refetchCallback}
              />
            );
          })}
      </div>
      {displayListOrSquareView === 0 && entries.length > 0 && (
        <MetaDataStrippedTable entries={entries} type={data.type} filter={filter} columns={columns} onDelete={refetchCallback} />
      )}
      {editorInitialState && (
        <EditorProvider injectedState={editorInitialState}>
          <EditSheet editItem={editItemCallback} />
        </EditorProvider>
      )}
    </FlexContainer>
  );
};
