import styled from '@emotion/styled';
import Modal from '@ingka/modal';
import Button from '@ingka/button';

export { FlexedModalFooter } from 'components/styles';

export const ModalWithOverflow = styled(Modal)`
  .prompt {
    overflow: visible;
  }
`;

export const ButtonFilter = styled(Button)`
  width: fit-content;
`;
