import React, { useContext } from 'react';
import { DropdownOptionProps } from 'components/Dropdown';
import { useGetHfbDropdownOptionsQuery } from '__generated__/types';
import * as Styled from 'components/Control/styles';
import { Context as MetaDataContext } from '../../../hooks/contexts/MetaDataContext';

type HFBDropdownProps = {
  value: string;
  onChange: (value: DropdownOptionProps) => void;
};
export const HFBDropdown: React.FC<HFBDropdownProps> = ({ value, onChange }) => {
  const {
    state: {
      metaDataContext: { country, language, type },
    },
  } = useContext(MetaDataContext);
  const { data } = useGetHfbDropdownOptionsQuery({
    variables: { country, language, type },
  });
  const options: DropdownOptionProps[] =
    data?.hfbDropdownOptions.map(({ text, key }) => ({
      value: key,
      label: text,
    })) ?? [];
  return <Styled.FilterDropdown id={'hfb-select'} value={value} onOptionChange={onChange} options={options} hintText={"All HFB's"} />;
};
