import styled from '@emotion/styled';
import Button from '@ingka/button';
import Pill from '@ingka/pill';

export const EditDeleteButtonContainer = styled.div`
  display: flex;
  gap: $(space50);
`;

export const EditDeleteButton = styled(Button)`
  min-height: 2.225rem;
  min-width: 7rem;
`;

export const PillButton = styled(Pill)`
  background-color: transparent;
`;
