import styled from '@emotion/styled';

interface ReviewWarningProps {
  clickable: boolean;
}

export const ReviewWarning = styled.span<ReviewWarningProps>`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'normal')};
  display: inline-block;
`;

export const BadgesColumn = styled.div`
  flex: 0 0 150px;
  padding-bottom: 1rem;
`;

export const StatsColumn = styled.div`
  width: 100%;
`;
