import React from 'react';
import Modal, { ModalHeader, Sheets } from '@ingka/modal';
import * as Styled from './styles';

type SurveyModalProps = {
  isVisible: boolean;
  question: string;
  buttons: React.ReactNode;
  children?: React.ReactNode;
  onClickClose: () => void;
};

export const SurveyModal: React.FC<SurveyModalProps> = ({ isVisible, question, buttons, children, onClickClose }) => {
  return (
    <Modal visible={isVisible} handleCloseBtn={() => onClickClose()}>
      <Sheets
        alignment="right"
        size={'small'}
        header={<ModalHeader title={question} />}
        footer={<Styled.FlexedModalFooter renderBorder={true}>{buttons}</Styled.FlexedModalFooter>}
      >
        <Styled.ModalBodyWithLessPadding>{children}</Styled.ModalBodyWithLessPadding>
      </Sheets>
    </Modal>
  );
};
