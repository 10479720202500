import styled from '@emotion/styled';
import TextArea from '@ingka/text-area';
import { space50, space75 } from '@ingka/variables';

export const FeedbackButtonContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
`;

export const SurveyTextArea = styled(TextArea)`
  margin-top: ${space50};
  margin-bottom: ${space75};

  label > p {
    height: 1.5rem;
    text-align: center;
    color: #484848 !important;
  }

  textarea {
    resize: none;
  }
`;
