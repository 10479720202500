import React, { ReactNode, useCallback, useRef, useState } from 'react';
import * as Styled from 'components/FloatingMenu/styles';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

type FloatingMenuProps = {
  children: (open: boolean, toggleOpen: () => void) => ReactNode;
  cancellation?: boolean;
  preventCloseOnClick?: boolean;
};

export const FloatingMenu: React.FC<FloatingMenuProps> = ({ children, preventCloseOnClick = false }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  useOnClickOutside([ref], () => setOpen(false), preventCloseOnClick);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return <Styled.Wrapper ref={ref}>{children(open, toggleOpen)}</Styled.Wrapper>;
};
