import styled from '@emotion/styled';
import IngkaTable from '@ingka/table';
import { space125, space50 } from '@ingka/variables';
import { colourNeutral2, colourTextAndIcon4 } from '@ingka/variables/colours-css';
import { FlexContainer } from 'components/FlexContainer';

export const Table = styled(IngkaTable)<{
  targetElementIndex?: number;
  offsettop?: number;
}>`
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  height: ${(props) => `calc(100vh - ${props.offsettop}px - ${space125} - ${space50})`};

  th {
    padding-left: 12px;
    padding-right: 12px;

    :nth-of-type(${(props) => props.targetElementIndex || 2}):nth-of-type(${(props) => (props.targetElementIndex || 2) + 1}) {
      width: 100%;
    }
  }

  .text {
    color: #111111 !important;
  }

  .table,
  tbody,
  td {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    vertical-align: middle;
    word-break: break-word;

    :nth-of-type(${(props) => props.targetElementIndex || 2}):nth-of-type(${(props) => (props.targetElementIndex || 2) + 1}) {
      width: 25%;
    }
  }

  tr {
    :nth-of-type(even) {
      background-color: ${colourNeutral2};
    }

    :nth-of-type(odd) {
      background-color: transparent;
    }
  }
`;

export const TableColumnHeading = styled(FlexContainer)`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    width: 22px;
    height: 22px;
  }

  &:hover {
    #sort-icon {
      visibility: visible;
      color: ${colourTextAndIcon4};
    }
  }
`;
