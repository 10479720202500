import styled from '@emotion/styled';
import { space125, space50 } from '@ingka/variables/design-tokens';
import Pill from '@ingka/pill';

interface MetaDataCollapsedTableRowProps {
  clickable?: boolean;
}

export const MetaDataCollapsedTableRow = styled.tr<MetaDataCollapsedTableRowProps>`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'normal')};

  td:has(button) {
    min-width: 7rem;
    padding-right: 0;
  }
`;
export const MetaDataButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space50};
  justify-content: flex-end;
  padding-right: ${space125};
  padding-block: ${space50};
`;

export const ChevronBtn = styled(Pill)`
  pointer-events: none;
  background-color: transparent;
`;
