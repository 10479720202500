import styled from '@emotion/styled';
import { FlexContainer } from 'components/FlexContainer';

export const BookmarkletWrapper = styled(FlexContainer)`
  width: 60%;
  margin: auto;
  margin-top: 50px;
  max-width: 400px;
`;

export const BookmarkletButtonWrapper = styled(FlexContainer)`
  position: relative;
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const BookmarkletButton = styled.a`
  width: 100%;
`;

export const BookmarkletArrow = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  transform: translate(-80%, -55%);

  path {
    fill: #8e8c8c;
  }
`;
