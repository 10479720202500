import styled from '@emotion/styled';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { space125, space25 } from '@ingka/variables';
import { FlexContainer } from 'components/FlexContainer';

export const ActiveFiltersRowContainer = styled(FlexContainer)`
  margin-bottom: ${space125};
`;
export const ClearAllFilters = styled(Text)`
  margin-left: ${space25};
  cursor: pointer;
  color: rgb(37, 76, 150);

  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonFilter = styled(Button)`
  width: fit-content;
`;
