import styled from '@emotion/styled';
import { fontSize100, radiusS, space300, space50, space75, thicknessThin } from '@ingka/variables';
import { colourNeutral5, colourSemanticInformative } from '@ingka/variables/colours-css';

export const DatepickerInput = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12.5rem;
  background: #fff;
  border: ${thicknessThin} ${colourNeutral5} solid;
  border-radius: ${radiusS};
  height: ${space300};
  font-size: ${fontSize100};
  cursor: pointer;
  padding: 0 ${space75};

  :focus {
    border: 2px solid ${colourSemanticInformative};
  }
`;

export const DateInputsWrapper = styled.div`
  width: 28.125rem;
  display: grid;
  gap: ${space50};
  grid-template-columns: repeat(2, 1fr);
`;
