import React, { JSX, useContext } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import warning from '@ingka/ssr-icon/paths/warning-triangle';
import { FlexContainer } from 'components/FlexContainer';
import { Context as EditorContext, FormKeys } from 'hooks/contexts/EditorContext';
import { useValidation } from 'hooks/useValidation';
import { TextAreaInputField } from '../TextAreaInputField';
import * as Styled from '../styles';

interface EditorTextAreaInputProps {
  id: FormKeys;
  placeholder?: string;
  disabled?: boolean;
  label: string;
  needsReview?: boolean;
  maxLength?: number;
  showLength?: boolean;
  tooltipMessage?: JSX.Element;
  translationButton?: boolean;
}

export const EditorTextAreaInput: React.FC<EditorTextAreaInputProps> = (props) => {
  const { id, label, needsReview, maxLength = 0 } = props;
  const { state, setField } = useContext(EditorContext);
  const value = (state.formData && state.formData[id]) ?? '';
  const { errorMessage } = useValidation(id, value);

  return (
    <>
      <TextAreaInputField
        {...props}
        id={id}
        label={label}
        shouldValidate
        errorMessage={errorMessage}
        onChange={(e) => setField(id, e.target.value)}
        value={value}
        characterLimit={maxLength}
      />
      <FlexContainer>
        {needsReview && (
          <Styled.NeedsReviewWarning>
            <SSRIcon paths={warning} height={'100'} viewBox={'0 0 28 28'} /> Review
          </Styled.NeedsReviewWarning>
        )}
      </FlexContainer>
    </>
  );
};
