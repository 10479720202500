import React, { FC } from 'react';
import ellipsis from '@ingka/ssr-icon/paths/ellipses-vertical';
import SSRIcon from '@ingka/ssr-icon';
import { PillProps } from '@ingka/pill';

import { FloatingMenuContent } from '../FloatingMenu/FloatingMenuContent';
import { FloatingMenu } from '../FloatingMenu';
import * as Styled from './styles';

export type FloatingMenuWithPillMenuItem = {
  onClick: () => void;
  text: string;
  icon?: (prefix?: string | undefined) => React.SVGProps<SVGElement>[];
  dataTestId: string;
  disabled?: boolean;
};

type FloatingMenuWithPillButtonProps = {
  menuItems: FloatingMenuWithPillMenuItem[];
  size?: PillProps['size'];
  disabled?: boolean;
};

export const FloatingMenuWithPillButton: FC<FloatingMenuWithPillButtonProps> = ({ menuItems, size = 'small', disabled }) => {
  return (
    <FloatingMenu>
      {(open, toggleOpen) => (
        <>
          <Styled.PillButton iconOnly ssrIcon={ellipsis} onClick={toggleOpen} data-testid={'floating-menu-btn'} size={size} disabled={disabled} />
          {open && (
            <FloatingMenuContent>
              <Styled.Menu>
                {menuItems.map((item) => (
                  <Styled.MenuItem key={item.text} onClick={item.onClick} data-testid={item.dataTestId} disabled={item.disabled}>
                    {item.icon && <SSRIcon paths={item.icon} />}
                    {item.text}
                  </Styled.MenuItem>
                ))}
              </Styled.Menu>
            </FloatingMenuContent>
          )}
        </>
      )}
    </FloatingMenu>
  );
};
