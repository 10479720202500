import { FC, useContext, useEffect, useState } from 'react';
import Text from '@ingka/text';
import { useRedirectRequests } from '../../../hooks/contexts/RedirectRequestsContext';

import { Context as LoginContext } from '../../../hooks/contexts/LoginContext';

import { getTotal404Pages, Total404PagesResponse } from '../../../api/redirectRequests';

import * as Styled from './styles';

export const Total404pages: FC = () => {
  const { selectedCountry, selectedDateRange } = useRedirectRequests();
  const { refreshToken } = useContext(LoginContext);
  const [total404Pages, setTotal404Pages] = useState<number>(0);

  useEffect(() => {
    refreshToken().then((token: string) => {
      getTotal404Pages(token, {
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        country: selectedCountry.countryCode,
      }).then((response: Total404PagesResponse) => setTotal404Pages(response.total));
    });
  }, [selectedDateRange.startDate, selectedDateRange.endDate, selectedCountry.countryCode]);

  return (
    <Styled.Total404sContainer>
      <Text tagName={'h4'}>Total 404 pages</Text>

      <Text tagName={'h2'} bodySize={'xl'}>
        {total404Pages}
      </Text>
    </Styled.Total404sContainer>
  );
};
