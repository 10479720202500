import styled from '@emotion/styled';

export const SearchDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.3rem;
`;

export const SearchDataItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.6rem;
`;

export const SearchDataLabel = styled.span`
  font-weight: bold;
  margin: 0 0.6rem;
`;
