import React, { useCallback, useEffect, useState } from 'react';
import * as Styled from './styles';
import chevronUp from '@ingka/ssr-icon/paths/chevron-up';
import SSRIcon from '@ingka/ssr-icon';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';

type Option = {
  id: string;
  name: string;
};

interface AutocompleteProps {
  options: Option[];
  value: string;
  onOptionSelect: (value: Record<string, string>) => void;
}

export const Autocomplete: React.FC<AutocompleteProps> = ({ options, onOptionSelect, value }) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setFilteredOptions(options.filter((option) => option.name.toLowerCase().includes(value.toLowerCase())));
  };

  const handleOptionSelect = (option: Option) => {
    setIsFocused(false);
    onOptionSelect(option);
  };

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Styled.AutocompleteContainer>
      <Styled.AutoCompleteInputWrapper>
        <Styled.AutoCompleteInputField
          id="autocomplete-input"
          aria-label="autocomplete-input"
          data-testid="autocomplete-input"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        <Styled.AutoCompleteIconButton onClick={() => setIsFocused(!isFocused)}>
          <SSRIcon paths={isFocused ? chevronUp : chevronDown} />
        </Styled.AutoCompleteIconButton>
      </Styled.AutoCompleteInputWrapper>
      {isFocused && filteredOptions.length > 0 && (
        <Styled.OptionsList>
          {filteredOptions.map((option) => (
            <Styled.OptionItem key={option.id} aria-label={option.name} onMouseDown={() => handleOptionSelect(option)}>
              {option.name}
            </Styled.OptionItem>
          ))}
        </Styled.OptionsList>
      )}
    </Styled.AutocompleteContainer>
  );
};
