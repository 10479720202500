import styled from '@emotion/styled';
import { colourTextAndIcon3 } from '@ingka/variables/colours-css.js';
import { breakpointXl, space75 } from '@ingka/variables';
import { FlexContainer } from '../../../components/FlexContainer';

import Text from '@ingka/text';

export const GraphContainer = styled(FlexContainer)`
  height: auto;
  min-width: 50vw;
  position: relative;
  @media screen and (min-width: ${breakpointXl}) {
    min-width: 60vw;
  }
`;
export const TooltipText = styled.p`
  min-width: 248px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  gap: ${space75};
`;

export const GrayText = styled(Text)`
  color: ${colourTextAndIcon3};
`;
