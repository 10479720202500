import React, { ChangeEvent, FC } from 'react';
import * as Styled from './styles';
import { Option, SelectProps } from '@ingka/select';

interface SelectComposition {
  Option: FC<SelectOptionProps>;
}

export type SelectOptionProps = { value: string; label: string };

interface StyledSelectProps extends SelectProps {
  id: string;
  value: string;
  width?: string;
  onClick?: () => void;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const Select: FC<StyledSelectProps> & SelectComposition = ({ children, onChange, width, ...rest }) => {
  return (
    <Styled.Select onChange={onChange} width={width} {...rest}>
      {children}
    </Styled.Select>
  );
};

const SelectOption: FC<SelectOptionProps> = ({ value, label }) => {
  return <Option value={value} name={label} />;
};

Select.Option = SelectOption;

export default Select;
