import styled from '@emotion/styled';
import { colourInteractiveDisabled1, colourNeutral5, colourSemanticNegative, colourSemanticPositive } from '@ingka/variables/colours-css';
import { fontSize100, radiusS, space300, space75 } from '@ingka/variables';
import { thicknessThin } from '@ingka/variables/design-tokens';

export const DatePickerWrapper = styled.div<{ isDisabled?: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? colourInteractiveDisabled1 : 'inherit')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'inherit')};
`;
export const DatePickerInput = styled.input<{
  validation: 'error' | 'success' | 'default';
}>`
  border-width: ${thicknessThin};
  border-color: ${({ validation }) =>
    (validation === 'success' && colourSemanticPositive) || (validation === 'error' && colourSemanticNegative) || colourNeutral5};
  border-radius: ${radiusS};
  height: ${space300};
  font-size: ${fontSize100};
  cursor: pointer;
  width: 100%;
  padding: 0 ${space75};

  &:disabled {
    border-color: ${colourInteractiveDisabled1};
    opacity: 0.4;
  }
`;
