import { FC, ReactElement, ReactNode } from 'react';
import * as Styled from './styles';
import { LoadingBallSize } from '@ingka/loading';
import { Loader } from '../Loader';
import Text from '@ingka/text';
import { FlexContainer } from '../FlexContainer';

interface DashboardSectionParams {
  id?: string;
  loading: boolean;
  children: ReactNode;
  heading: ReactNode;
  toolTip?: ReactElement;
  loadingBallSize?: LoadingBallSize;
  minHeight?: string;
}

export const DashboardSection: FC<DashboardSectionParams> = ({ id, children, heading, loading, loadingBallSize, minHeight, toolTip }) => {
  return (
    <Styled.SectionWrapper direction={'column'} gap={0.75}>
      <FlexContainer gap={0.5} align={'center'}>
        <Text tagName="h4" headingSize="xs" data-testid={id}>
          {heading}
        </Text>
        {toolTip && toolTip}
      </FlexContainer>
      {loading ? <Loader loadingBallSize={loadingBallSize} minHeight={minHeight} /> : children}
    </Styled.SectionWrapper>
  );
};
