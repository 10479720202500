import { UploadNewLocale } from 'features/admin/UploadNewLocale';
import Container from 'components/Container';
import { useContext, useEffect } from 'react';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { useHistory } from 'react-router-dom';
import { Context as ErrorContext } from 'hooks/contexts/ErrorMessageContext';

export const UploadPage = () => {
  const { isAdmin } = useContext(LoginContext);
  const { goBack } = useHistory();
  const { setErrorMessage } = useContext(ErrorContext);

  useEffect(() => {
    if (!isAdmin()) {
      setErrorMessage({
        message: 'Let’s get you back on track…',
        title: '401 authorisation required',
        action: () => goBack(),
      });
    }
  }, []);

  return (
    <Container page="upload">
      <UploadNewLocale />
    </Container>
  );
};
