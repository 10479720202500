import React, { useContext } from 'react';
import Button from '@ingka/button';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right-small';
import { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import FileSaver from 'file-saver';
import { request } from 'gaxios';
import * as Styled from 'features/PoliciesTable/styles';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { Policy } from 'types';
import { FloatingMenuWithPillButton, FloatingMenuWithPillMenuItem } from 'components/FloatingMenuWithPillButton';
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up-to-line';
import { defaultToastContent, ToastEventTypes, useToast } from 'hooks/contexts/ToastContext';

type TableProps = {
  policies: Policy[];
  columnHeaders: string[];
};

export const PoliciesTable: React.FC<TableProps> = ({ policies, columnHeaders }) => {
  const { setUploadRulesModalValues } = useContext(RulesContext);
  const { refreshToken } = useContext(LoginContext);
  const { setToastContent, toastContent } = useToast();
  const downloadFile = async (country: string, language: string, type: string | undefined) => {
    const path = type === 'short' ? `ikea.${country}` : `${country}-${language}`;
    const token = await refreshToken();
    try {
      setToastContent({ type: ToastEventTypes.FILE_DOWNLOAD });
      const res = await request<string>({
        method: 'POST',
        responseType: 'blob',
        url: `/api/redirect/rules/${path}/download`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return FileSaver.saveAs(res.data, `${path}_rules.xlsx`);
    } catch (e) {
      console.log(e);
    } finally {
      setToastContent(defaultToastContent);
    }
  };

  const uploadFile = async (country: string, language: string, type: string | undefined) => {
    const policy = type === 'short' ? `ikea.${country}` : `${country}-${language}`;
    setUploadRulesModalValues({ isOpen: true, country, policy });
  };
  const getFloatingMenuItems = (country: string, language: string, type: string | undefined): FloatingMenuWithPillMenuItem[] => {
    return [
      {
        onClick: () => downloadFile(country, language, type),
        text: 'Download',
        icon: arrowDown,
        dataTestId: 'download-xlsx-btn',
        disabled: toastContent.type === ToastEventTypes.FILE_DOWNLOAD,
      },
      {
        onClick: () => uploadFile(country, language, type),
        text: 'Upload',
        icon: arrowUp,
        dataTestId: 'upload-xlsx-btn',
      },
    ];
  };
  return (
    <Styled.Table fullWidth>
      <TableHeader>
        <tr>
          {columnHeaders.map((columnHeader, index) => (
            <th role="columnheader" key={index}>
              {columnHeader}
            </th>
          ))}
        </tr>
      </TableHeader>
      <TableBody>
        {policies.map(({ country, language, modified, id, type }, index) => {
          return (
            <tr key={index} data-testid={`row-${id}`}>
              <td>
                <Text>{id?.toUpperCase()}</Text>
              </td>
              <td>
                <Text>{type === 'short' ? 'Short URL' : 'Default URL'}</Text>
              </td>
              <td>
                <Text>{modified}</Text>
              </td>
              <td>
                <Button
                  text="View"
                  iconPosition="trailing"
                  ssrIcon={chevronRight}
                  size={'small'}
                  type="secondary"
                  href={`/redirects/${country}/${id}`}
                  data-cy={'view-button'}
                />
              </td>
              <td>
                <FloatingMenuWithPillButton menuItems={getFloatingMenuItems(country, language, type)} />
              </td>
            </tr>
          );
        })}
      </TableBody>
    </Styled.Table>
  );
};
