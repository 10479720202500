import React, { useEffect, useState } from 'react';
import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import { CheckboxInput } from 'components/CheckBoxInput';
import { TextAreaInputField } from 'components/TextAreaInputField';
import { AnnouncementInput, useAnnouncementQuery, useUpdateAnnouncementMutation } from '__generated__/types';
import { defaultToastContent, ToastEventTypes, useToast } from '../../../hooks/contexts/ToastContext';

export const AnnouncementForm: React.FC = () => {
  const { loading, data } = useAnnouncementQuery();
  const [updateAnnouncement] = useUpdateAnnouncementMutation();
  const [formData, setFormData] = useState({
    enabled: false,
    message: '',
    emergencyVariant: false,
  });
  const { setToastContent } = useToast();

  useEffect(() => {
    if (!data?.announcement) return;
    const { message, enabled, emergencyVariant } = data.announcement;
    setFormData({ message, enabled, emergencyVariant });
  }, [data?.announcement]);

  const setFieldValue = <K extends keyof AnnouncementInput>(key: K, value: AnnouncementInput[K]) =>
    setFormData((prev) => ({ ...prev, [key]: value }));
  const handleSubmit = async (values: AnnouncementInput) => {
    return updateAnnouncement({ variables: { announcement: values } })
      .then(() => {
        setToastContent({ type: ToastEventTypes.SAVED });
      })
      .catch(() => {
        setToastContent({ type: ToastEventTypes.SAVE_FAILED });
      })
      .then(() => {
        setTimeout(() => setToastContent(defaultToastContent), 3000);
      });
  };
  if (loading) {
    return (
      <Loading text={'Please wait...'}>
        <LoadingBall />
      </Loading>
    );
  }
  return (
    <>
      <CheckboxInput
        shouldValidate={false}
        id={'enabled'}
        label={'Enabled'}
        onChange={() => setFieldValue('enabled', !formData.enabled)}
        checked={formData.enabled}
      />
      <CheckboxInput
        shouldValidate={false}
        id={'emergencyVariant'}
        label={'Emergency variant'}
        onChange={() => setFieldValue('emergencyVariant', !formData.emergencyVariant)}
        checked={formData.emergencyVariant}
      />
      <TextAreaInputField
        shouldValidate={false}
        id={'message'}
        label={'Message'}
        onChange={(e) => setFieldValue('message', e.target.value)}
        value={formData.message}
      />
      <Button text={'Save'} onClick={async () => await handleSubmit(formData)} type={'primary'} size={'small'} />
    </>
  );
};
