import styled from '@emotion/styled';
import { space125 } from '@ingka/variables';

export const Container = styled.div`
  padding: ${space125};
`;

export const NoNewOrModifiedRules = styled.div`
  display: flex;
  min-height: 400px;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  border-top: 1px solid lightgrey;
`;

export const FullPageLoader = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 70px);
`;
