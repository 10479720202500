import * as React from 'react';
import { PaginatedTable } from 'components/PaginatedTable';
import { Column } from 'features/seo/UploadResult/Table/UploadTable';
import { paginateData } from 'opt-util/paginate';
import { Message } from 'types';
import * as Styled from '../styles';

const columns: Column[] = [
  { key: 'id', label: 'ID' },
  {
    key: 'messages',
    label: 'Messages',
    render: (val: string[]) => (
      <ul>
        {val.map((error, index) => (
          <Styled.ErrorMessageListItem key={index}>{error}</Styled.ErrorMessageListItem>
        ))}
      </ul>
    ),
  },
];

interface UploadErrorsProps {
  messages: Message[];
}

export const UploadErrors: React.FC<UploadErrorsProps> = ({ messages }) => {
  const errorMessages = paginateData(messages);
  return errorMessages.totalItems === 0 ? null : (
    <>
      <hr />
      <h4>Errors (The below records will not be uploaded)</h4>
      <PaginatedTable columns={columns} data={errorMessages} testid={'upload-errors'} />
    </>
  );
};
