import React from 'react';
import { Avatar } from '../../../components/Avatar';
import { AvatarVariant } from '@ingka/avatar';

type ProfileImageProps = {
  image: string | null;
  variant?: AvatarVariant;
};

export const ProfileImage: React.FC<ProfileImageProps> = ({ image, variant }) => {
  return (
    <div data-testid="profile-header">
      {image ? (
        <Avatar
          imageProps={{
            src: image,
          }}
        />
      ) : (
        <span data-testid={'profile initials avatar'}>
          <Avatar variant={variant} />
        </span>
      )}
    </div>
  );
};
