import styled from '@emotion/styled';
import { colourElevation1Border, colourNeutral7, colourStaticBlack, colourStaticLightGrey } from '@ingka/variables/colours-css';
import { fontSize50, fontSize75, fontWeightBold, radiusCap, radiusS, space200, space50, space75 } from '@ingka/variables';
import Button from '@ingka/button';
import React from 'react';
import { FlexContainer } from '../FlexContainer';

export const TooltipContainer = styled(FlexContainer)`
  position: relative;
`;

export interface TooltipMessageProps extends React.HTMLAttributes<HTMLSpanElement> {
  left?: string;
  top?: string;
}

export const TooltipMessage = styled.span<TooltipMessageProps>`
  position: absolute;
  left: ${({ left }) => left || '-250px'};
  top: ${({ top }) => top || '150%'};
  max-width: 530px;
  background-color: ${colourNeutral7};
  color: white;
  padding: ${space50} ${space75};
  font-size: ${fontSize50};
  border-radius: ${radiusS};

  ul {
    list-style: none;

    li {
      margin-bottom: ${space50};
    }
  }
`;

export const ToolTipButton = styled(Button)`
  align-items: center;
  background: ${colourStaticLightGrey};
  border: 0;
  border-radius: ${radiusCap};
  color: ${colourStaticBlack};
  cursor: pointer;
  display: inline-flex;
  font-size: ${fontSize75};
  font-weight: ${fontWeightBold};
  height: ${space200};
  justify-content: center;
  line-height: 1.4285714286;
  outline: 0;
  padding: 0;
  position: relative;
  text-align: center;
  transition-duration: 0.25s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
  vertical-align: top;
  width: ${space200};

  &:hover {
    background: ${colourElevation1Border};

    span.btn__inner {
      background: none;
    }
  }
`;
