import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { FilterInput } from '__generated__/types';

export type FilterInputRules = {
  targetStatus?: 200 | 301 | 404 | null;
  invocations?: 'high' | 'low' | null;
  startDateUsage?: string;
  endDateUsage?: string;
};

export type FilterContextInputs = Partial<FilterInput & FilterInputRules> & {
  page: number;
};
export type FilterContextInputsKey = keyof FilterContextInputs;
export type FilterContextInputsKeyWithoutPage = Omit<FilterContextInputsKey, 'page'>;
export type FilterContextInputsWithoutPage = Omit<FilterContextInputs, 'page'>;

type FilterContextType = {
  filter: FilterContextInputs;
  setFilter: Dispatch<SetStateAction<FilterContextInputs>>;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

const FilterProvider: FC<{
  children: ReactNode;
  injectedFilter?: FilterContextInputs;
}> = ({ children, injectedFilter }) => {
  const [filter, setFilter] = useState<FilterContextInputs>(
    injectedFilter || {
      page: 1,
    },
  );
  useEffect(() => {
    setFilter((prev) => ({ ...prev, page: 1 }));
  }, [
    filter?.textFilter,
    filter?.valid,
    filter?.optimized,
    filter?.selfCanonicalized,
    filter?.hasProducts,
    filter?.needsReview,
    filter?.addedLast14Days,
    filter?.homeFurnishingBusinessId,
    filter?.invocations,
    filter?.targetStatus,
    filter?.catalogName,
    filter?.startDateUsage,
    filter?.endDateUsage,
  ]);
  return <FilterContext value={{ filter, setFilter }}>{children}</FilterContext>;
};

const useFilter = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};

export { FilterProvider, useFilter };
