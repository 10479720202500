import React, { useEffect, useRef, useState } from 'react';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Table, { TableBody, TableHeader } from '@ingka/table';
import { useSitemapsMarkets } from 'features/sitemaps/hooks';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useRequestWithTokenAndTransformer } from 'hooks/useWithToken';
import { Market, SitemapStatsObject, TableRowData, UrlTypeDetails } from 'types/sitemaps';
import * as Styled from './styles';

const TableCell: React.FC<{
  data?: UrlTypeDetails;
  market: Market;
}> = (props) => {
  const [extraInfoVisible, setExtraInfoVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside([ref], () => setExtraInfoVisible(false));

  return (
    <td onClick={() => setExtraInfoVisible(true)}>
      {extraInfoVisible && (
        <Styled.TooltipWrapper ref={ref}>
          <Styled.TooltipContent>
            <Styled.ExtraInfo>
              <Styled.ExtraInfoHeading>
                Statistics for {props.market.language}_{props.market.country}
              </Styled.ExtraInfoHeading>

              <span>Urls: {props.data?.urlCount || '-'}</span>
              <span>Images: {props.data?.imageUrlCount || '-'}</span>
              <span>Videos: {props.data?.videoUrlCount || '-'}</span>
              <span>Missing files: {props.data?.videoUrlCount || '-'}</span>
            </Styled.ExtraInfo>
          </Styled.TooltipContent>
        </Styled.TooltipWrapper>
      )}
      {getCount(props.data)}
    </td>
  );
};

const getCount = (type: UrlTypeDetails | undefined) => (type ? type.urlCount : '-');
const getTotal = (...args: (UrlTypeDetails | undefined)[]) => args.reduce((acc, curr) => acc + (curr && curr.urlCount ? curr.urlCount : 0), 0);
const StatsTableRow = ({ market, cat, collection, edit, homepage, planner, prod, series, contentgallery, catalogfilter, veta }: TableRowData) => {
  return (
    <tr>
      <td>
        <a href={`/sitemaps/stats/${market.language}-${market.country}`}>
          {market.language}-{market.country}
        </a>
      </td>

      <TableCell data={cat} market={market} />
      <TableCell data={collection} market={market} />
      <TableCell data={series} market={market} />
      <TableCell data={prod} market={market} />
      <TableCell data={planner} market={market} />
      <TableCell data={homepage} market={market} />
      <TableCell data={edit} market={market} />
      <TableCell data={contentgallery} market={market} />
      <TableCell data={catalogfilter} market={market} />
      <TableCell data={veta} market={market} />
      <td>{getTotal(cat, collection, series, prod, planner, homepage, edit, contentgallery, catalogfilter, veta)}</td>
    </tr>
  );
};

export const SitemapStatsComponent: React.FC = () => {
  const [tableRowsData, setTableRowsData] = useState<TableRowData[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const markets = useSitemapsMarkets();
  const { data } = useRequestWithTokenAndTransformer<
    {
      stats: SitemapStatsObject[];
      date: string;
    },
    { stats: SitemapStatsObject[]; date: string }
  >({ url: '/api/sitemaps/stats/latest' }, [], (res) => {
    return { stats: res.stats, date: res.date };
  });

  useEffect(() => {
    const sitemapStats = data?.stats;
    if (markets && markets.length > 0 && sitemapStats && sitemapStats.length > 0) {
      const tableRowDataArray: TableRowData[] = [];
      // group data from sitemapStats per market
      markets.forEach((m) => {
        let currentMarket: TableRowData = { market: m.market };
        const exractedStatsForCurrent = sitemapStats.filter(
          (tr) => m.market.country === tr.market.country && m.market.language === tr.market.language,
        );

        exractedStatsForCurrent.forEach((item) => {
          currentMarket = {
            ...currentMarket,
            [item.type as keyof TableRowData]: {
              urlCount: item.urlCount,
              imageUrlCount: item.imageUrlCount,
              videoUrlCount: item.videoUrlCount,
              missingFiles: item.missingFiles,
              type: item.type,
            },
          };
        });
        tableRowDataArray.push(currentMarket);
      });
      setTableRowsData(tableRowDataArray);
    }
  }, [markets, data]);
  const filterTable = (item: TableRowData) => {
    const [filterLanguage, filterCountry] = filterValue.toLowerCase().split('-');
    const countryMatches = !filterCountry || filterCountry === '*' || item.market.country.toLowerCase().includes(filterCountry);
    const languageMatches = !filterLanguage || filterLanguage === '*' || item.market.language.toLowerCase().includes(filterLanguage);

    return countryMatches && languageMatches;
  };

  return (
    <>
      <Styled.MarketFilterContainer>
        <FormField fieldHelper={{ msg: 'ae-ar / *-ar / ae-*' }}>
          <InputField
            id="marketFilter"
            type="text"
            name="marketFilter"
            aria-required="false"
            label="Market filter"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          />
        </FormField>
      </Styled.MarketFilterContainer>
      <Table fullWidth>
        <TableHeader>
          <tr>
            <th>Market</th>
            <th>Category</th>
            <th>Collection</th>
            <th>Series</th>
            <th>Products</th>
            <th>Planners</th>
            <th>Homepage</th>
            <th>Editorial</th>
            <th>Inspiration</th>
            <th>Catalog filter</th>
            <th>VETA</th>
            <th>Total</th>
          </tr>
        </TableHeader>
        <TableBody>{tableRowsData?.filter(filterTable).map((item: TableRowData, i) => <StatsTableRow key={i} {...item} />)}</TableBody>
      </Table>
      {data?.date && (
        <span>
          {`Updated: ${new Date(data.date).toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}`}
        </span>
      )}
    </>
  );
};
