import React, { ChangeEvent } from 'react';
import Select, { Option, SelectProps } from '@ingka/select';

export type DropdownOptionProps = { value: string; label: string };

interface DropdownProps extends SelectProps {
  id: string;
  value: string;
  onOptionChange: (option: DropdownOptionProps) => void;
  options: DropdownOptionProps[];
}

export const Dropdown: React.FC<DropdownProps> = ({ options, onOptionChange, ...rest }) => {
  return (
    <Select
      {...rest}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = options[e.target.selectedIndex - 1];
        onOptionChange(selectedOption);
      }}
    >
      {options.map(({ value, label }) => (
        <Option key={value} value={value} name={label} />
      ))}
    </Select>
  );
};
