import React, { useCallback } from 'react';
import { PublishButton } from 'components/PublishButton';
import { GET_FILE_LIST_QUERY } from 'features/seo/queries';
import { useUploadPublishMutation } from '__generated__/types';

export const UploadPublishButton: React.FC<{
  uploadId: string;
  country: string;
  language: string;
}> = ({ country, language, uploadId }) => {
  const query = {
    query: GET_FILE_LIST_QUERY,
    variables: { country: country, language: language },
  };
  const [publish, { loading, error }] = useUploadPublishMutation({
    refetchQueries: [query],
  });
  const publishFn = useCallback(() => publish({ variables: { uploadId } }), [uploadId]);

  return <PublishButton publish={publishFn} isLoading={loading} country={country} language={language} errorMessage={error?.message} />;
};
