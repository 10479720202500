import styled from '@emotion/styled';
import Text from '@ingka/text';
import { space75 } from '@ingka/variables/design-tokens';

export const ModalHeader = styled.div`
  width: 100%;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background-color: #003e72;
  padding: ${space75};
`;

export const HeaderText = styled(Text)`
  color: #ffdb00;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;

  &:first-of-type {
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
  }
`;
