import React, { JSX, SyntheticEvent, useEffect, useState } from 'react';
import Button from '@ingka/button';
import Loading, { LoadingBall } from '@ingka/loading';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import * as Styled from 'components/styles';

interface PublishButtonProps {
  publish: (force: boolean) => void;
  isLoading: boolean;
  country: string;
  language: string;
  errorMessage?: string;
}

export const PublishButton: React.FC<PublishButtonProps> = (props: PublishButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('Publishing');
  const [text, setText] = useState(
    <>
      <p>Please wait while the metadata is published</p>
      <Loading text={''}>
        <LoadingBall />
      </Loading>
    </>,
  );

  const [footer, setFooter] = useState<JSX.Element | null>(null);
  const defaultFooter = (
    <ModalFooter>
      <Button
        data-testid={'publish-ok'}
        text={'OK'}
        type={'primary'}
        onClick={(e: SyntheticEvent) => {
          window.location.reload();
        }}
      />
    </ModalFooter>
  );

  const confirmFooter = (
    <ModalFooter>
      <Button text={'Cancel'} type={'secondary'} onClick={() => false} />
      <Button
        text={'Proceed'}
        type={'primary'}
        onClick={() => {
          props.publish(true);
        }}
      />
    </ModalFooter>
  );

  useEffect(() => {
    if (!props.isLoading && modalOpen) {
      if (props.errorMessage) {
        if (props.errorMessage.includes('More than 5%')) {
          setTitle('Many deletes detected');
          setText(<p>More than 5% of entries are going to be deleted. Proceed anyway?</p>);
          setFooter(confirmFooter);
        } else {
          setTitle('Something went wrong');
          setText(<p>{props.errorMessage}</p>);
          setFooter(defaultFooter);
        }
      } else {
        setTitle('Publish finished');
        setText(<p>Finished successfully</p>);
        setFooter(defaultFooter);
      }
    } else {
      setFooter(<ModalFooter />);
    }
  }, [props.isLoading, props.errorMessage]);

  return (
    <>
      <Styled.YellowButton
        text={'Publish'}
        type={'emphasised'}
        data-cy={'publish-button'}
        size={'small'}
        onClick={() => {
          props.publish(false);
          setModalOpen(true);
        }}
      />
      <Modal
        visible={modalOpen}
        escapable={false}
        data-testid={'publish-modal'}
        handleCloseBtn={() => {
          setModalOpen(false);
        }}
      >
        <Prompt titleId={title} title={title} header={<ModalHeader />} footer={footer}>
          {text}
        </Prompt>
      </Modal>
    </>
  );
};
