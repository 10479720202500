import { Dispatch, JSX } from 'react';
import { Action, createDataContext } from './createDataContext';

export interface ErrorContent {
  message?: string | JSX.Element;
  title?: string;
  action?: () => void;
  buttonText?: string;
}

interface State extends ErrorContent {
  hasError: boolean;
}

enum ActionType {
  SET_CLEAR_ERROR = 'clear',
  SET_ERROR = 'set',
}

const initialState: State = {
  hasError: false,
  message: undefined,
  title: undefined,
  action: undefined,
  buttonText: undefined,
};

const errorMessageReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_CLEAR_ERROR:
      return {
        ...state,
        ...initialState,
      };
    case ActionType.SET_ERROR:
      return {
        ...state,
        hasError: true,
        message: action.payload.message,
        title: action.payload.title,
        action: action.payload.action,
        buttonText: action.payload.buttonText,
      };
    default:
      return state;
  }
};

const setClearError = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: ActionType.SET_CLEAR_ERROR, payload: {} });
};

const setErrorMessage = (dispatch: Dispatch<Action>) => (error: ErrorContent) => {
  dispatch({ type: ActionType.SET_ERROR, payload: error });
};

export const { Provider, Context } = createDataContext<State>(
  errorMessageReducer,
  {
    setClearError,
    setErrorMessage,
  },
  { ...initialState },
);
