import React from 'react';
import { DropdownOptionProps } from 'components/Dropdown';
import { SortInput } from '__generated__/types';
import * as Styled from 'components/Control/styles';

type SortDropdownProps = {
  sort: SortInput;
  onSortChange: (sort: SortInput) => void;
};

const sortOptions: DropdownOptionProps[] = [
  { value: 'search_clicks,desc', label: 'Most clicks' },
  { value: 'search_clicks,asc', label: 'Least clicks' },
  { value: 'search_ctr,desc', label: 'Highest CTR' },
  { value: 'search_ctr,asc', label: 'Lowest CTR' },
  { value: 'search_impressions,desc', label: 'Most impressions' },
  { value: 'search_impressions,asc', label: 'Least impressions' },
  { value: 'avg_position,asc', label: 'Avg. Pos. (Good)' },
  { value: 'avg_position,desc', label: 'Avg. Pos. (Bad)' },
];
export const SortDropdown: React.FC<SortDropdownProps> = ({ sort, onSortChange }) => {
  return (
    <Styled.FilterDropdown
      id={'sort-dropdown'}
      data-testid={'filter-sort-dropdown'}
      value={`${sort.field},${sort.direction}`}
      onOptionChange={({ value }: DropdownOptionProps) => {
        const [field, direction] = value.split(',');
        onSortChange({ field, direction });
      }}
      options={sortOptions}
    />
  );
};
