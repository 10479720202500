import React, { FC, ReactNode } from 'react';
import Button from '@ingka/button';
import { FloatingMenu } from '../FloatingMenu';
import { FloatingMenuContent } from '../FloatingMenu/FloatingMenuContent';
import { FlexContainer } from '../FlexContainer';
import * as Styled from './styles';

type FloatingMenuWithActionButtonsProps = {
  children: ReactNode;
  onApply: () => void;
  onCancel: () => void;
  onClickReset: () => void;
  isapplyDisabled?: boolean;
  toggleButton: (toggleOpen: () => void) => ReactNode;
  floatingLeftPosition?: string;
  floatingTopPosition?: string;
};

export const FloatingMenuWithActionButtons: FC<FloatingMenuWithActionButtonsProps> = ({
  onClickReset,
  isapplyDisabled,
  onCancel,
  onApply,
  children,
  toggleButton,
  floatingLeftPosition,
  floatingTopPosition,
}) => {
  return (
    <FloatingMenu preventCloseOnClick>
      {(isOpen, toggleOpen) => (
        <>
          {toggleButton(toggleOpen)}
          {isOpen && (
            <FloatingMenuContent leftPosition={floatingLeftPosition} topPosition={floatingTopPosition}>
              <Styled.InnerContainer>
                {children}
                <FlexContainer justify="space-between">
                  <Button text="Reset" type="tertiary" size={'small'} onClick={onClickReset} />
                  <FlexContainer gap={1}>
                    <Button
                      text="Cancel"
                      type="secondary"
                      size={'small'}
                      onClick={() => {
                        onCancel();
                        toggleOpen();
                      }}
                    />
                    <Button
                      text="Apply"
                      type="primary"
                      disabled={isapplyDisabled}
                      size={'small'}
                      onClick={() => {
                        onApply();
                        toggleOpen();
                      }}
                    />
                  </FlexContainer>
                </FlexContainer>
              </Styled.InnerContainer>
            </FloatingMenuContent>
          )}
        </>
      )}
    </FloatingMenu>
  );
};
