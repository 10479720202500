import React from 'react';
import ToastIngka from '@ingka/toast';
import { defaultToastContent, ToastEventTypes, useToast } from '../../hooks/contexts/ToastContext';

const toastMessages: Record<ToastEventTypes, string> = {
  download: 'File downloading',
  saved: 'Saved successfully',
  save_failed: 'Failed to save',
};

export const Toast = () => {
  const { setToastContent, toastContent } = useToast();
  return (
    <>
      <ToastIngka
        text={<span>{toastContent.type && toastMessages[toastContent.type]}</span>}
        isOpen={!!toastContent.type}
        onCloseRequest={() => {
          setToastContent(defaultToastContent);
        }}
        ariaLabelCloseBtn="Dismiss notification"
      />
    </>
  );
};
