import styled from '@emotion/styled';
import { space200 } from '@ingka/variables';

export const MenuList = styled.ul`
  padding-top: 1rem;
`;

export const MenuItem = styled.li`
  list-style: inside;
  padding: 0.2rem 0;
`;

export const UploadSuccess = styled.h4`
  color: #0a8a00;
  padding-bottom: 1rem;
`;

export const ConfirmationMessage = styled.h3`
  text-align: center;
  padding: ${space200} 0;
`;
