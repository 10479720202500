import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

export function useOnClickOutside(
  refs: RefObject<HTMLElement | null>[],
  handler: (evt: MouseEvent | TouchEvent) => void,
  preventClick: boolean | undefined = false,
): void {
  useEffect(() => {
    if (preventClick) {
      return;
    }
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (refs.some((ref) => !ref.current || ref.current.contains((event.target as Node) || null))) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
}
