import React, { FC, useContext } from 'react';
import { TranslationModalButton } from 'components/TranslationModalButton';
import { Context as EditorContext } from 'hooks/contexts/EditorContext';

type TranslationPluginProps = {
  visibility: boolean;
  onModalClose: () => void;
  originalText: string;
  targetLanguage?: string;
  fieldId?: string;
};

export const TranslationPlugin: FC<TranslationPluginProps> = ({ fieldId, targetLanguage, visibility, onModalClose, originalText }) => {
  const { setField } = useContext(EditorContext);
  const OnTranslationSave = (newText: string) => {
    setField(fieldId, newText);
  };
  return (
    <TranslationModalButton
      targetLanguage={targetLanguage}
      visible={visibility}
      onModalClose={onModalClose}
      originalText={originalText}
      onSaveTranslation={OnTranslationSave}
    />
  );
};
