import React, { useContext, useState } from 'react';
import Button from '@ingka/button';
import { CheckboxInput } from 'components/CheckBoxInput';
import { DropdownInput, DropdownOption } from 'components/DropdownInput';
import { TextInputField } from 'components/TextInputField';
import { FlexContainer } from 'components/FlexContainer';
import { SiteStructure } from 'hooks/contexts/SiteStructureContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

interface SiteStructureFormProps {
  data: SiteStructure;
  setEdit: (edit: boolean) => void;
  submitAction: (siteStructure: SiteStructure, refreshToken: () => Promise<string>) => Promise<void>;
  showId: boolean;
}

const typeOptions: DropdownOption[] = [
  { text: 'pages', key: 'pages', value: 'pages' },
  { text: 'resources', key: 'resources', value: 'resources' },
];

const ownerOptions: DropdownOption[] = [
  { key: 'editorial', text: 'editorial', value: 'editorial' },
  { key: 'range', text: 'range', value: 'range' },
  { key: 'search', text: 'search', value: 'search' },
  { key: 'navigation', text: 'navigation', value: 'navigation' },
  { key: 'recommendations', text: 'recommendations', value: 'recommendations' },
  { key: 'profiles', text: 'profiles', value: 'profiles' },
  { key: 'local', text: 'local', value: 'local' },
  { key: 'framework', text: 'framework', value: 'framework' },
  { key: 'analytics', text: 'analytics', value: 'analytics' },
];

export const INCORRECT_DATE = 'Date should be in format YYYY-MM-DD';

const validDate = (value: string | undefined): string => {
  const dateRegex = /^2[0-9]{3}-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/;
  return !value || !dateRegex.test(value) ? INCORRECT_DATE : '';
};

export const SiteStructureForm: React.FC<SiteStructureFormProps> = ({ data, showId, setEdit, submitAction }) => {
  const [formData, setFormData] = useState(data);
  const { refreshToken } = useContext(LoginContext);

  const setFieldValue = <K extends keyof SiteStructure>(key: K, value: SiteStructure[K]) =>
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  const handleSubmit = async (siteStructure: SiteStructure) => {
    const token = await refreshToken();
    await submitAction(siteStructure, token);
    setEdit(false);
  };
  return (
    <FlexContainer direction={'column'} grow={1} margin={1}>
      {showId && <input type={'hidden'} name={'_id'} />}
      <TextInputField
        shouldValidate={false}
        id={'id'}
        label={'ID'}
        onChange={(e) => setFieldValue('id', e.target.value)}
        value={formData['id'] ?? ''}
      />
      <TextInputField
        shouldValidate={false}
        id={'slug'}
        label={'Slug'}
        onChange={(e) => setFieldValue('slug', e.target.value)}
        value={formData['slug']}
      />
      <TextInputField
        shouldValidate={false}
        id={'description'}
        label={'Description'}
        onChange={(e) => setFieldValue('description', e.target.value)}
        value={formData['description']}
      />
      <DropdownInput
        id={'type'}
        label={'Type'}
        options={typeOptions}
        onChange={(e) => setFieldValue('type', e.target.value)}
        value={formData['type']}
      />
      <DropdownInput
        id={'owner'}
        label={'Owner'}
        options={ownerOptions}
        onChange={(e) => setFieldValue('owner', e.target.value)}
        value={formData['owner']}
      />
      <TextInputField
        shouldValidate={!!formData['startDate']}
        id={'startDate'}
        label={'Start date [YYYY-MM-DD]'}
        errorMessage={validDate(formData['startDate'])}
        placeholder={'YYYY-MM-DD'}
        onChange={(e) => setFieldValue('startDate', e.target.value)}
        value={formData['startDate'] ?? ''}
      />
      <TextInputField
        shouldValidate={!!formData['endDate']}
        id={'endDate'}
        label={'End date [YYYY-MM-DD]'}
        errorMessage={validDate(formData['endDate'])}
        placeholder={'YYYY-MM-DD'}
        onChange={(e) => setFieldValue('endDate', e.target.value)}
        value={formData['endDate'] ?? ''}
      />
      <CheckboxInput
        shouldValidate={false}
        id={'allowLocalSections'}
        label={'Allow Local Sections'}
        onChange={() => setFieldValue('allowLocalSections', !formData['allowLocalSections'])}
        checked={formData['allowLocalSections']}
      />
      <CheckboxInput
        id={'allowLocalPages'}
        shouldValidate={false}
        label={'Allow Local Pages'}
        onChange={() => setFieldValue('allowLocalPages', !formData['allowLocalPages'])}
        checked={formData['allowLocalPages']}
      />
      <CheckboxInput
        id={'allowLocalApps'}
        shouldValidate={false}
        label={'Allow Local Apps'}
        onChange={() => setFieldValue('allowLocalApps', !formData['allowLocalApps'])}
        checked={formData['allowLocalApps']}
      />
      <CheckboxInput
        id={'allowPlanners'}
        shouldValidate={false}
        label={'Allow Planners'}
        onChange={() => setFieldValue('allowPlanners', !formData['allowPlanners'])}
        checked={formData['allowPlanners']}
      />
      <FlexContainer direction={'row'} gap={1}>
        <Button text={'OK'} type={'primary'} onClick={() => handleSubmit(formData)} size={'small'} />
        <Button text={'Cancel'} type={'secondary'} onClick={() => setEdit(false)} size={'small'} />
      </FlexContainer>
    </FlexContainer>
  );
};
