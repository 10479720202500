import Flag from 'react-country-flag';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const flagStyling = css`
  width: 30px !important;
  height: 20px !important;
  margin-right: 10px;
`;

export const CountryFlag = styled(Flag)`
  ${flagStyling}
`;

export const FallbackCountryFlag = styled.img`
  ${flagStyling}
`;
