import { gql } from 'apollo-boost';

export const GET_EXISTING_SITE_FOLDERS = gql`
  query ExistingSiteFolders {
    existingSiteFolders
  }
`;

export const CREATE_NEW_LOCALE = gql`
  mutation CreateLocale($country: String!, $language: String!) {
    createNewLocale(country: $country, language: $language) {
      _id
      _id
    }
  }
`;

export const GET_URL_SLUG_CONFIG = gql`
  query GetUrlSlugConfig {
    getUrlSlugSyncConfig {
      country
      configs {
        country
        enabled
        language
      }
    }
  }
`;
export const UPDATE_URL_SLUG_CONFIG = gql`
  mutation UpdateUrlSlugConfig($urlSlugConfig: UrlSlugSyncInput!) {
    updateUrlSlugSyncConfig(urlSlugSyncConfig: $urlSlugConfig) {
      country
    }
  }
`;

export const ANNOUNCEMENT_QUERY = gql`
  query announcement {
    announcement {
      enabled
      emergencyVariant
      message
    }
  }
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement($announcement: AnnouncementInput!) {
    updateAnnouncement(announcement: $announcement) {
      enabled
      emergencyVariant
      message
    }
  }
`;

export const MD_TO_HTML = gql`
  query mdToHTML($text: String!) {
    mdToHTML(text: $text)
  }
`;

export const TRANSLATE_TO_TARGET_LANGUAGE = gql`
  query translate($targetLanguage: String!, $text: String!) {
    translate(targetLanguage: $targetLanguage, text: $text)
  }
`;

export const FILTERED_URL_QUERY = gql`
  query filter($url: String!, $slug: String!) {
    getFilteredUrl(url: $url, slug: $slug) {
      preview
      globalId
      suggestions {
        slug
        h1
        title
      }
    }
  }
`;

export const CREATE_NEW_ENTRIES = gql`
  mutation saveNewEntries($entries: [MetaDataEntryInput!]!) {
    saveNewFilteredPages(entries: $entries) {
      _id
    }
  }
`;
