import React, { FC } from 'react';
import Text from '@ingka/text';
import Button from '@ingka/button';
import { useRedirectRequests } from 'hooks/contexts/RedirectRequestsContext';
import { FlexContainer } from 'components/FlexContainer';
import { CountryDropdown } from '../CountryDropdown';
import { NoData } from 'components/NoData';
import { BarChart } from 'components/BarChart';
import { formatDataForGraph, graphOptions } from './graphConfig';
import { DatePicker } from '../DatePicker';
import { DashboardSection } from '../../../components/DashboardSection';
import { Total404pages } from '../Total404pages';
import { Most404pages } from '../Most404pages';
import { Tooltip } from '../../../components/Tooltip';

import * as Styled from './styles';

export const RedirectRequests: FC = () => {
  const {
    isLoading: loadingGraphData,
    redirectsGraphDailyRequestsCount,
    onGraphDataRefetch,
    timeFetched,
    errorRedirectsGraphData,
  } = useRedirectRequests();

  const GraphTooltip = (
    <Tooltip left={'40px'} top={'0'}>
      <Styled.TooltipText>
        This graph shows the number of requests to ikea.com that have been replied with a 404 response over time for the selected country.
      </Styled.TooltipText>
    </Tooltip>
  );

  return (
    <FlexContainer direction={'column'}>
      <FlexContainer direction={'column'} justify={'space-between'} mb={1} gap={1}>
        <Text headingSize={'m'}>Overview</Text>
        <FlexContainer justify={'space-between'}>
          <FlexContainer align={'center'} gap={1}>
            <Button text={'Refresh'} size={'small'} role={'button'} data-testid={'refresh-button'} onClick={onGraphDataRefetch} />
            <Styled.GrayText tagName="span" bodySize={'s'}>
              <strong>Last Fetched:</strong> {timeFetched}
            </Styled.GrayText>
          </FlexContainer>
          <FlexContainer gap={1} data-testid={'redirectRequests-selects'}>
            <CountryDropdown />
            <DatePicker />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <Styled.Container>
        <Styled.GraphContainer>
          <DashboardSection heading={'Trend of 404 pages'} loading={loadingGraphData} minHeight={'400px'} id={'graph-title'} toolTip={GraphTooltip}>
            {redirectsGraphDailyRequestsCount && !errorRedirectsGraphData ? (
              <BarChart data={formatDataForGraph(redirectsGraphDailyRequestsCount, 404)} options={graphOptions} />
            ) : (
              <NoData
                imageFlexPosition={'column'}
                heading={'No data available'}
                text={'No data found for this data range or market. Try adjusting selection'}
                textAlign={'center'}
                contentAlign={'center'}
                id={'redirect-requests-no-data'}
              />
            )}
          </DashboardSection>
        </Styled.GraphContainer>
        <FlexContainer direction={'column'} gap={0.75}>
          <Total404pages />
          <Most404pages />
        </FlexContainer>
      </Styled.Container>
    </FlexContainer>
  );
};
