import React, { useContext } from 'react';
import { FlexContainer } from 'components/FlexContainer';
import { KeywordsTable } from 'components/KeywordsTable';
import { OmitTN } from 'opt-util/types';
import { Context as MetaDataContext } from 'hooks/contexts/MetaDataContext';
import { MetaDataExtendedFragment } from '__generated__/types';
import { SearchConsoleData } from '../SearchConsoleData';
import { StatusBadge } from '../StatusBadge';
import { isNotOlderThan14Days } from '../../../../utils/dates';
import * as Styled from './styles';

interface BadgesProps {
  hideBadges: boolean;
  entry: OmitTN<MetaDataExtendedFragment>;
  type: string;
}

interface ReviewWarningMessageProps {
  clickable: boolean;
  onClick?: () => void;
  entry: OmitTN<MetaDataExtendedFragment>;
}

export const InfoBox: React.FC<BadgesProps> = ({ hideBadges, entry, type }) => {
  const { setIsEditorOpen } = useContext(MetaDataContext);
  const { isOptimized, needsReview, hasProducts, createdAt } = entry;
  const addedLast14Days = isNotOlderThan14Days(createdAt);
  const suggestionsAvailable = !!entry.suggestedPrimaryKeyword || !!entry.suggestedSecondaryKeyword || !!entry.suggestedAlternativeKeyword;
  const showReviewWarning = needsReview && type !== 'filter';

  return (
    <>
      <FlexContainer>
        <Styled.BadgesColumn>
          {!hideBadges && (
            <>
              {addedLast14Days && !isOptimized && <StatusBadge statusType="addedLast14Days" status />}
              <StatusBadge statusType="isOptimized" status={!!isOptimized} />
              {needsReview && <StatusBadge statusType="needsReview" status />}
              {!hasProducts && hasProducts !== null && <StatusBadge statusType="hasNoProducts" status />}
            </>
          )}
        </Styled.BadgesColumn>
        <Styled.StatsColumn>
          {entry.searchConsoleData && <SearchConsoleData searchConsoleData={entry.searchConsoleData} />}
          {suggestionsAvailable && <KeywordsTable entry={entry} />}
          <div>{showReviewWarning && <ReviewWarningMessage clickable onClick={() => setIsEditorOpen(entry.id)} entry={entry} />}</div>
        </Styled.StatsColumn>
      </FlexContainer>
    </>
  );
};

export const ReviewWarningMessage: React.FC<ReviewWarningMessageProps> = ({ clickable, onClick, entry }) => {
  const reviewWarningKategoriseraTitleChanged = (
    <Styled.ReviewWarning clickable={clickable} onClick={onClick}>
      Category name has changed from <b>{entry.previousKategoriseraTitle}</b>
      to <b>{entry.kategoriseraTitle}</b>, please review the inputs.
    </Styled.ReviewWarning>
  );

  const fallBackReviewWarning = (
    <Styled.ReviewWarning clickable={clickable} onClick={onClick}>
      Please review the inputs with the warning symbol.
    </Styled.ReviewWarning>
  );
  return entry.previousKategoriseraTitle ? reviewWarningKategoriseraTitleChanged : fallBackReviewWarning;
};
