import React from 'react';
import { Tab, TabPanel } from '@ingka/tabs';
import Container from 'components/Container';
import Tabs from 'components/Tabs';
import { Catalog } from 'features/graphs/Catalog';
import { Global } from 'features/graphs/Global';
import { PageHeader } from '../styles';

export const GraphsPage: React.FC = () => (
  <Container>
    <PageHeader>Graphs</PageHeader>
    <style>
      {`
        .tabs__tab {
          padding: 0rem .25rem .5rem .25rem;
        }
      `}
    </style>
    <Tabs
      tabs={[<Tab key={'tab-1'} tabPanelId={'catalog'} text={'Catalog'} />, <Tab key={'tab-2'} tabPanelId={'global'} text={'Global'} />]}
      tabPanels={[
        <TabPanel key={'panel-1'} tabPanelId={'catalog'}>
          <Catalog />
        </TabPanel>,
        <TabPanel key={'panel-2'} tabPanelId={'global'}>
          <Global />
        </TabPanel>,
      ]}
      defaultActiveTab={'catalog'}
    />
  </Container>
);
