import React, { useContext, useEffect, useRef, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { TextAreaInputField } from '../../../components/TextAreaInputField';
import Button from '@ingka/button';
import { EditSheetContentSidebarComment } from '../EditSheetContentSidebarComment';
import { GaxiosResponse, request } from 'gaxios';
import { environment } from '../../../environment';
import { Divider } from '../../../components/Divider';
import { FlexContainer } from '../../../components/FlexContainer';
import * as Styled from './styles';
import { Context as LoginContext } from '../../../hooks/contexts/LoginContext';

export type MetaDataCommentResponse = {
  key: string;
  data: {
    image: string;
    createdAt: string;
    displayName: string;
    text: string;
    userId: string;
  };
};

export type MetaDataComment = {
  key: string;
  image: string;
  createdAt: Date;
  displayName: string;
  userId: string;
  text: string;
};

type MetadataEntryKey = {
  country: string;
  language: string;
  type: string;
  id: string;
};

type EditSheetCommentProps = MetadataEntryKey & { isActiveTab: boolean };

export const EditSheetComments: React.FC<EditSheetCommentProps> = ({ country, language, type, id, isActiveTab }) => {
  const {
    state: { token },
  } = useContext(LoginContext);
  const { oid } = jwt_decode<{ oid: string }>(token);

  const [comments, setComments] = useState<MetaDataComment[]>([]);
  const [commentTextField, setCommentTextField] = useState('');

  const commentsEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollDownCommentsList = () => {
      commentsEndRef.current?.scrollIntoView();
    };
    scrollDownCommentsList();
  }, [isActiveTab, comments]);

  const commentsUrl = environment.serviceUrl + `/optimera/metadata/${country}/${language}/${type}/${id}/comments`;

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const { data, status }: GaxiosResponse<MetaDataCommentResponse[]> = await request({
          url: commentsUrl,
          headers: { Authorization: `Bearer ${token}` },
        });
        if (status === 200) {
          setComments(data.map(toMetadataComment));
        }
      } catch (err) {}
    };

    fetchComments();
  }, [token]);

  const handleCreateComment = async () => {
    const createCommentBody = { userId: oid, text: commentTextField.trim() };
    setCommentTextField('');

    try {
      const { data, status }: GaxiosResponse = await request({
        url: commentsUrl,
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        data: createCommentBody,
      });

      if (status === 201) {
        setComments((prev) => [...prev, toMetadataComment(data)]);
      }
    } catch (err) {}
  };

  const handleDeleteComment = async (key: string) => {
    try {
      const { status }: GaxiosResponse = await request({
        url: `${commentsUrl}/${key}`,
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (status === 204) {
        setComments((prev) => prev.filter((comment) => comment.key !== key));
      }
    } catch (err) {}
  };

  const isUserAuthor = (userId: string) => oid === userId;

  return (
    <Styled.EditSheetCommentSectionContainer direction={'column'} justify={'space-between'}>
      <Styled.EditSheetCommentListContainer>
        {comments.map(({ key, image, createdAt, displayName, text, userId }, i) => (
          <div key={key}>
            <EditSheetContentSidebarComment
              id={key}
              image={image}
              text={text}
              displayName={displayName}
              createdAt={createdAt}
              onDelete={() => handleDeleteComment(key)}
              isDeleteButtonEnabled={isUserAuthor(userId)}
            />
            {i <= comments.length - 2 && <Divider />}
          </div>
        ))}
        <div ref={commentsEndRef} />
      </Styled.EditSheetCommentListContainer>

      <Styled.AddCommentContainer direction="column">
        <Styled.CommentControlsContainerDivider />
        <Styled.CommentControlsContainer direction={'column'} justify={'flex-end'}>
          <TextAreaInputField
            id="comment-text-area"
            label="Add a comment"
            value={commentTextField}
            onChange={(event) => setCommentTextField(event.target.value)}
          />
          <FlexContainer justify={'flex-end'}>
            <Button text="Post comment" type="emphasised" size="xsmall" disabled={commentTextField.length === 0} onClick={handleCreateComment} />
          </FlexContainer>
        </Styled.CommentControlsContainer>
      </Styled.AddCommentContainer>
    </Styled.EditSheetCommentSectionContainer>
  );
};

const toMetadataComment = (comment: MetaDataCommentResponse): MetaDataComment => ({
  key: comment.key,
  image: comment.data.image,
  createdAt: new Date(comment.data.createdAt),
  displayName: comment.data.displayName,
  text: comment.data.text,
  userId: comment.data.userId,
});
