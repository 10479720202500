import styled from '@emotion/styled';
import TextArea from '@ingka/text-area';
import { fontSize50 } from '@ingka/variables';
import { colourNeutral2 } from '@ingka/variables/colours-css';

export const OverflowLabelTextArea = styled(TextArea)<{ readOnly?: boolean }>`
  label {
    overflow: visible;
    display: flex;
    font-size: ${fontSize50};
  }

  .text-area__wrapper {
    textarea {
      background-color: ${({ readOnly }) => (readOnly ? colourNeutral2 : 'inherit')};
    }

    .text-area__border {
      border: ${({ readOnly }) => (readOnly ? colourNeutral2 : 'auto')};
    }
  }

  textarea {
    font-size: ${fontSize50};
  }
`;
