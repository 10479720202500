import styled from '@emotion/styled';
import { radiusS, space50, space75, thicknessThin } from '@ingka/variables';
import { FlexContainer } from '../../../components/FlexContainer';
import { colourNeutral3, colourStaticGrey } from '@ingka/variables/colours-css';

export const Most404sContainer = styled(FlexContainer)`
  border: ${thicknessThin} solid ${colourNeutral3};
  border-radius: ${radiusS};
  flex-direction: column;
  padding: ${space75};
  gap: ${space75};
`;

export const Most404PagesRows = styled(FlexContainer)`
  flex-direction: column;

  > div {
    padding-top: ${space50};
    padding-bottom: ${space50};
    border-bottom: ${thicknessThin} solid ${colourStaticGrey};
  }

  > div:last-child {
    border-bottom: none;
  }
`;
