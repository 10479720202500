import styled from '@emotion/styled';

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ResultColumn = styled.div`
  width: 50%;
`;
