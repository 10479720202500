import * as React from 'react';
import * as Styled from './styles';

interface StatusBadgeProps {
  statusType: 'isOptimized' | 'isPublished' | 'needsReview' | 'hasNoProducts' | 'addedLast14Days';
  status: boolean;
}

enum COLORS {
  GREEN = '#070',
  RED = '#c00',
  YELLOW = '#CA5008',
  BLUE = '#0058A3',
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ statusType, status }) => {
  switch (statusType) {
    case 'isOptimized':
      return status ? (
        <Styled.Badge data-testid={'optimized'} color={COLORS.GREEN}>
          Optimized
        </Styled.Badge>
      ) : (
        <Styled.Badge data-testid={'not-optimized'} color={COLORS.RED}>
          Not Optimized
        </Styled.Badge>
      );
    case 'isPublished':
      return status ? (
        <Styled.Badge data-testid={'published'} color={COLORS.GREEN}>
          Published
        </Styled.Badge>
      ) : (
        <Styled.Badge data-testid={'not-published'} color={COLORS.RED}>
          Not Published
        </Styled.Badge>
      );
    case 'needsReview':
      return <Styled.Badge color={COLORS.YELLOW}>Needs Review</Styled.Badge>;

    case 'addedLast14Days':
      return <Styled.Badge color={COLORS.BLUE}>New</Styled.Badge>;

    case 'hasNoProducts':
      return <Styled.Badge color={COLORS.YELLOW}>Has No Products</Styled.Badge>;
  }
};
