export const getDateRangeForLast30Days = () => {
  const now = new Date();
  return {
    startDate: datePickerOptions[3].calcStartDate(now).toISOString().split('T')[0],
    endDate: datePickerOptions[3].calcEndDate(now).toISOString().split('T')[0],
  };
};

export const datePickerOptions: Array<{
  label: string;
  value: string;
  calcStartDate: (today: Date) => Date;
  calcEndDate: (today: Date) => Date;
}> = [
  {
    label: 'Last 7 days',
    value: 'last7Days',
    calcStartDate: (today: Date) => new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
    calcEndDate: (today: Date) => today,
  },
  {
    label: 'Last 14 days',
    value: 'last14Days',
    calcStartDate: (today: Date) => new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14),
    calcEndDate: (today: Date) => today,
  },
  {
    label: 'Last 28 days',
    value: 'last28Days',
    calcStartDate: (today: Date) => new Date(today.getFullYear(), today.getMonth(), today.getDate() - 28),
    calcEndDate: (today: Date) => today,
  },
  {
    label: 'Last 30 days',
    value: 'last30Days',
    calcStartDate: (today: Date) => new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30),
    calcEndDate: (today: Date) => today,
  },
  {
    label: 'Today',
    value: 'today',
    calcStartDate: (today: Date) => today,
    calcEndDate: (today: Date) => today,
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    calcStartDate: (today: Date) => new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
    calcEndDate: (today: Date) => new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
  },
  {
    label: 'This Month',
    value: 'thisMonth',
    calcStartDate: (today: Date) => new Date(today.getFullYear(), today.getMonth(), 1),
    calcEndDate: (today: Date) => today,
  },
  {
    label: 'Last Month',
    value: 'lastMonth',
    calcStartDate: (today: Date) => new Date(today.getFullYear(), today.getMonth() - 1, 1),
    calcEndDate: (today: Date) => new Date(today.getFullYear(), today.getMonth(), 0),
  },
  {
    label: 'This Year',
    value: 'thisYear',
    calcStartDate: (today: Date) => new Date(today.getFullYear(), 0, 1),
    calcEndDate: (today: Date) => today,
  },
  {
    label: 'Custom',
    value: 'custom',
    calcStartDate: (today: Date) => today,
    calcEndDate: (today: Date) => today,
  },
];
