import React, { FC } from 'react';
import * as Styled from './styles';

interface DividerProps extends React.HTMLAttributes<HTMLHRElement> {
  vertical?: boolean;
}

export const Divider: FC<DividerProps> = ({ vertical }) => {
  return vertical ? (
    <Styled.StyledDiv>
      <Styled.StyledDivider />
    </Styled.StyledDiv>
  ) : (
    <hr className="divider" />
  );
};
