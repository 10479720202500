export const getHFBFieldLabel = (key: string): string | undefined => {
  return {
    '01': 'Living room seating',
    '02': 'Store and organise furniture',
    '03': 'Workspaces',
    '04': 'Bedroom furniture',
    '05': 'Beds & Mattresses',
    '06': 'Bathroom & Water',
    '07': 'Kitchen',
    '08': 'Dining',
    '09': 'Children´s IKEA',
    '10': 'Lighting & Home electronics',
    '11': 'Bed and bath textiles',
    '12': 'Home textiles',
    '13': 'Rugs',
    '14': 'Cooking',
    '15': 'Eating',
    '16': 'Decoration',
    '17': 'Outdoor',
    '18': 'Home organisation',
    '20': 'Other business opportunities',
    '70': 'Home Appliances',
    '95': 'Administrative Area 95',
    '96': 'IKEA Food',
  }[key];
};
