import styled from '@emotion/styled';

export const PageNumbers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

export const Page = styled.span<{ selected?: boolean }>`
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  cursor: pointer;
  width: 4rem;
  text-align: center;
  margin: 0 0.3em;
  background-color: ${({ selected }) => (selected ? '#f0f0f0' : 'white')};

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const Summary = styled.div`
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0 0.3em;
  background-color: 'white';
`;
