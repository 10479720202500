import styled from '@emotion/styled';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import { ModalBody, ModalFooter } from '@ingka/modal';
import Text from '@ingka/text';
import { space100, space125, space75 } from '@ingka/variables';
import InputField from '@ingka/input-field';
import List from '@ingka/list';
import { colourNeutral2 } from '@ingka/variables/colours-css';

const colorBrandBlue = '#0058a3';

export const NeedsReviewWarning = styled.p`
  color: #f26a2f;
  position: relative;
`;

export const Label = styled.span<{ flexSize?: number }>`
  font-weight: bold;
  flex: 0 0 ${(props) => props.flexSize || 150}px;
`;

export const BlueLabel = styled(Label)`
  color: ${colorBrandBlue};
  cursor: pointer;
`;

export const Value = styled.span`
  overflow-wrap: break-word;
  word-break: break-all;
`;

export const ErrorMessage = styled.div`
  padding: 1em;
  color: red;
`;

export const Header = styled.h3`
  display: flex;
  align-items: center;
`;

export const Spacer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`;

export const SpacedList = styled(List)`
  margin-bottom: 0.75rem;
`;

export const FlexedModalFooter = styled(ModalFooter)`
  display: flex;
  padding-left: ${space125} !important;
  padding-right: ${space125} !important;
  padding-bottom: ${space100} !important;
  padding-top: ${space100} !important;
`;

export const FlexedModalBody = styled(ModalBody)<{
  margin?: string;
  width?: string;
}>`
  padding: ${space75};
  width: ${(props) => props.width};
  margin-left: ${(props) => props.margin};
  @media (min-width: 1200px) {
    width: 100%;
  }
`;

export const Menu = styled.div<{ leftPosition?: string }>`
  position: absolute;
  border: 1px solid #ddd;
  left: ${(props) => (props.leftPosition ? props.leftPosition : '-150px')};
  z-index: 2;
  background-color: white;
  border-radius: 8px;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const YellowButton = styled(Button)`
  color: #111111;
  background-color: #ffdb00;

  &:focus,
  &:hover {
    color: #111111;
  }

  .btn__inner {
    background-color: #ffdb00;
    border: none;

    &:active,
    &:hover {
      background-color: #ffdb00;
    }
  }

  &:hover {
    .btn__inner {
      box-shadow: none;
    }
  }
`;

export const DeleteErrorInlineMessage = styled(InlineMessage)`
  margin-bottom: 1rem;
`;

export const ConfirmDeleteText = styled(Text)`
  margin-bottom: 1rem;
`;

export const InputTextField = styled(InputField)<{ readOnly?: boolean }>`
  .input-field__wrapper {
    background-color: ${({ readOnly }) => (readOnly ? colourNeutral2 : 'inherit')};

    .input-field__border {
      border: ${({ readOnly }) => (readOnly ? colourNeutral2 : 'auto')};
    }
  }
`;
