import React from 'react';
import { SortInput } from '__generated__/types';
import { HFBDropdown } from '../HEFDropdown';
import { SortDropdown } from '../SortDropdown';
import * as Styled from './styles';
import { FilterContextInputs } from 'hooks/contexts/FilterContext';
import { getMetadataFilterOptions, getRedirectFilterOptions } from './filterOptions';
import { PageWithControlName } from '../index';
import { OnFilterChange } from '../FilterAndSortModal';
import { Autocomplete } from '../../Autocomplete';
import { CatalogReference } from 'types';
import { FlexContainer } from '../../FlexContainer';
import { Features, useFeatureContext } from 'hooks/contexts/FeatureContext';
import { space125 } from '@ingka/variables';
import Accordion, { AccordionItem } from '@ingka/accordion';

type ValidityFilterProps = {
  type: string;
  sort?: SortInput;
  onFilterChange: OnFilterChange;
  onSortChange: (sort: SortInput) => void;
  pageName: PageWithControlName;
  filter?: FilterContextInputs;
  categories?: CatalogReference[] | null;
};

export const ValidityFilter: React.FC<ValidityFilterProps> = ({ type, sort, onFilterChange, onSortChange, pageName, filter, categories }) => {
  const { validArray, selfCanonicalArray, hasProductsArray, optimizedArray } = getMetadataFilterOptions(onFilterChange, { filter, type });
  const { targetStatusArray, usageArray } = getRedirectFilterOptions(onFilterChange, { filter });
  const { isFeatureDisabled } = useFeatureContext();
  const isPipCategoriesFilterDisabled = isFeatureDisabled(Features.PIP_CATEGORIES_FILTER);
  const rulesRadioOptions = [
    { name: 'Target Status', list: targetStatusArray, id: 'target_status' },
    {
      name: 'Usage',
      list: usageArray,
      id: 'usage',
    },
  ];
  return (
    <Styled.ValidityFilterWrapper direction={'column'} p={space125}>
      <Accordion collapsible={false}>
        {pageName === 'metadata' && (
          <>
            <AccordionItem id="Optimized" title={type !== 'filter' ? 'Optimized' : 'Published'} data-testid={'accordion_optimized'}>
              <FlexContainer direction="column" gap={space125}>
                <Styled.ValidityFilterRadioButtonGroup list={optimizedArray} subtle data-testid={'radioGroup_optimized'} />
                <Styled.ValidityFilterCheckbox
                  name={'needsReview=filter'}
                  key={'needsReview=filter'}
                  id={'needsReview=filter'}
                  checked={!!filter?.needsReview}
                  label={'Needs Review'}
                  value={'needsReview'}
                  onChange={() => onFilterChange('needsReview', filter?.needsReview ? null : true)}
                  subtle
                />
                <Styled.ValidityFilterCheckbox
                  name={'addedLast14Days=filter'}
                  key={'addedLast14Days=filter'}
                  id={'addedLast14Days=filter'}
                  checked={!!filter?.addedLast14Days}
                  label={'Pages Added Last 14 Days'}
                  value={'addedLast14Days'}
                  onChange={() => onFilterChange('addedLast14Days', filter?.addedLast14Days ? null : true)}
                  subtle
                />
                {type === 'PIP' && (
                  <>
                    <HFBDropdown
                      value={filter?.homeFurnishingBusinessId ?? ''}
                      onChange={({ value }) => onFilterChange('homeFurnishingBusinessId', value)}
                    />
                    {categories && isPipCategoriesFilterDisabled && (
                      <Autocomplete
                        options={categories}
                        value={filter?.catalogName ?? 'All Categories'}
                        onOptionSelect={(value) => onFilterChange('catalogName', value.name)}
                      />
                    )}
                  </>
                )}
                {type === 'PLP' && sort && <SortDropdown sort={sort} onSortChange={onSortChange} />}
              </FlexContainer>
            </AccordionItem>
            <AccordionItem id="valid" title={'Valid'} data-testid={'accordion_valid'}>
              <Styled.ValidityFilterRadioButtonGroup list={validArray} subtle data-testid={'radioGroup_valid'} />
            </AccordionItem>
            <AccordionItem id="self_canonicalized" title={'Self Canonicalized'} data-testid={'accordion_self_canonicalized'}>
              <Styled.ValidityFilterRadioButtonGroup list={selfCanonicalArray} subtle data-testid={'radioGroup_self_canonicalized'} />
            </AccordionItem>
            {type === 'PLP' && (
              <AccordionItem id="has_products" title={'Has Products'} data-testid={'accordion_has_products'}>
                <Styled.ValidityFilterRadioButtonGroup list={hasProductsArray} subtle data-testid={'radioGroup_has_products'} />
              </AccordionItem>
            )}
          </>
        )}
        {pageName === 'rules' &&
          rulesRadioOptions.map(({ name, list, id }) => (
            <AccordionItem id={id} title={name} data-testid={`accordion_${id}`}>
              <Styled.ValidityFilterRadioButtonGroup list={list} key={id} subtle data-testid={`radioGroup_${id}`} />`
            </AccordionItem>
          ))}
      </Accordion>
    </Styled.ValidityFilterWrapper>
  );
};
