import React from 'react';
import { StatusIcon } from 'components/StatusIcon';
import { Column as MetaDataTableColumn } from 'types';
import { MetaDataEntry } from '__generated__/types';
import { FlexContainer } from '../../../../components/FlexContainer';
import { isNotOlderThan14Days } from '../../../../utils/dates';
import { TableCell } from '../../../../components/Table/TableCell';

interface MetaDataCellProps {
  entry: MetaDataEntry;
  type: string;
  column: MetaDataTableColumn;
}

export const MetaDataCell: React.FC<MetaDataCellProps> = ({ entry, type, column }) => {
  const addedLast14Days = isNotOlderThan14Days(entry.createdAt);
  switch (column.value) {
    case 'pageTitle':
    case 'urlSlug':
    case 'robots':
    case 'author':
    case 'publiceraId':
      return <TableCell text={entry?.[column.value] ?? ''} />;
    case 'sectionName':
      return <TableCell text={entry?.section?.name ?? ''} />;
    case 'entryId':
      return <TableCell text={entry?.id ?? ''} textAlign={'right'} />;
    case 'ts':
      return entry?.ts ? <TableCell text={new Date(entry.ts).toLocaleString()} textAlign={'right'} /> : null;
    case 'status':
      return (
        <TableCell>
          <FlexContainer direction={'row'} gap={1}>
            <StatusIcon statusType={entry.needsReview ? 'needsReview' : 'isOptimized'} status={!!entry.isOptimized} />
            {!entry.isOptimized && addedLast14Days && <StatusIcon statusType="addedLast14Days" status />}
          </FlexContainer>
        </TableCell>
      );
    default:
      return null;
  }
};
