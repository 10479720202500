import React from 'react';
import { BlueLabel } from 'components/styles';
import * as Styled from './styles';

interface SearchConsoleDataProps {
  avg_position: number;
  search_clicks: number;
  search_ctr: string;
  search_impressions: number;
}

export const SearchConsoleData: React.FC<{
  searchConsoleData: SearchConsoleDataProps | null;
}> = ({ searchConsoleData }) => {
  if (!searchConsoleData) {
    return null;
  }
  return (
    <Styled.SearchDataWrapper>
      <BlueLabel>SEO metrics</BlueLabel>
      <Styled.SearchDataItem data-testid="search_clicks">
        <Styled.SearchDataLabel>Clicks</Styled.SearchDataLabel>
        <span>{searchConsoleData.search_clicks}</span>
      </Styled.SearchDataItem>
      <Styled.SearchDataItem data-testid="search_ctr">
        <Styled.SearchDataLabel>CTR</Styled.SearchDataLabel>
        <span>{searchConsoleData.search_ctr}</span>
      </Styled.SearchDataItem>
      <Styled.SearchDataItem data-testid="search_impressions">
        <Styled.SearchDataLabel>Impressions</Styled.SearchDataLabel>
        <span>{searchConsoleData.search_impressions}</span>
      </Styled.SearchDataItem>
      <Styled.SearchDataItem data-testid="avg_position">
        <Styled.SearchDataLabel>Average position</Styled.SearchDataLabel>
        <span>{searchConsoleData.avg_position}</span>
      </Styled.SearchDataItem>
    </Styled.SearchDataWrapper>
  );
};
