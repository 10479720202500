import React from 'react';
import { KeyValue } from 'components/KeyValue';
import { FlexContainer } from 'components/FlexContainer';

export const getPercentageOptimized = (optimized: number, total: number): string => {
  const percentage = (optimized / total) * 100;
  if (isNaN(percentage)) {
    return '- %';
  } else {
    return `${percentage.toFixed(2)} %`;
  }
};

export const MetadataStats: React.FC<{
  totalItems: number;
  optimizedItems: number;
}> = (props) => {
  return (
    <FlexContainer direction={'column'}>
      <KeyValue label={'Total optimized'} value={props.optimizedItems.toFixed(0)} labelSize={250} />
      <KeyValue label={'Total non-optimized'} value={(props.totalItems - props.optimizedItems).toFixed(0)} labelSize={250} />
      <KeyValue label={'Optimized'} value={getPercentageOptimized(props.optimizedItems, props.totalItems)} labelSize={250} />
    </FlexContainer>
  );
};
