import styled from '@emotion/styled';
import { colourNeutral2, colourTextAndIcon2 } from '@ingka/variables/colours-css';

import { space200 } from '@ingka/variables';
import { FlexContainer } from '../FlexContainer';
import Text from '@ingka/text';

export const NoDataWrapper = styled.div`
  padding: ${space200};
  background-color: ${colourNeutral2};
`;

export const NoDataImage = styled.img`
  max-width: 8.5rem;
`;

export const NoDataContent = styled(FlexContainer)<{
  textAlign?: string;
}>`
  max-width: 18.25rem;
  text-align: ${(props) => props.textAlign || 'start'};
`;

export const NoDataTitle = styled(Text)`
  color: ${colourTextAndIcon2};
`;
