import styled from '@emotion/styled';
import Select from '@ingka/select';

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
`;

export const FullscreenSVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 200px);
`;

export const InfoContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  max-height: 70vh;
  overflow: auto;
`;

export const InfoBox = styled.div`
  position: relative;
  padding: 15px;
  margin-bottom: 4px;
  background-color: #ccdeed;
  border: 2px solid #669bc8;
  border-radius: 5px;
  cursor: text;
  max-width: 400px;
`;

export const ResizedSelect = styled(Select)`
  max-width: 200px;
  position: relative;
  z-index: 10;
`;
