import React, { ChangeEvent, ReactElement } from 'react';
import FormField from '@ingka/form-field';
import * as Styled from './styles';

interface TextAreaInputFieldProps {
  id: string;
  placeholder?: string;
  disabled?: boolean;
  label: string | ReactElement;
  shouldValidate?: boolean;
  errorMessage?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  ariaLabelledby?: string;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  characterLimit?: number;
}

export const TextAreaInputField: React.FC<TextAreaInputFieldProps> = ({
  id,
  placeholder,
  disabled,
  label,
  shouldValidate,
  errorMessage,
  value,
  onChange,
  ariaLabelledby,
  onFocus,
  characterLimit,
}) => {
  return (
    <FormField
      validation={{
        msg: errorMessage,
        id: `${id}-error-message`,
      }}
      valid={!errorMessage}
      shouldValidate={shouldValidate}
      characterLimit={characterLimit}
      disabled={disabled}
    >
      <Styled.OverflowLabelTextArea
        aria-labelledby={ariaLabelledby}
        readOnly={disabled}
        id={id}
        name={id}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
    </FormField>
  );
};
