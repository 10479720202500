import styled from '@emotion/styled';
import { thicknessThin } from '@ingka/variables';
import { colourStaticGrey } from '@ingka/variables/colours-css';

export const StyledDivider = styled.hr`
  border-block-start: ${thicknessThin} solid ${colourStaticGrey};
  margin: 0;
  height: 100%;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
