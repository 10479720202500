import { FC } from 'react';
import {
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  ScatterDataPoint,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);
export type ChartBarData = ChartData<'bar', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>;

export const BarChart: FC<{
  options: ChartOptions<'bar'>;
  data: ChartBarData;
}> = ({ options, data }) => {
  return <Bar options={options} data={data} data-testid={'bar-chart'} />;
};
