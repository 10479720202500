import styled from '@emotion/styled';
import Modal, { ModalFooter } from '@ingka/modal';
import { FlexContainer } from 'components/FlexContainer';

export const TranslationModalInputContainer = styled(FlexContainer)`
  height: 85%;

  textarea {
    min-width: 30vw;
    height: 100%;
    resize: none;
  }

  .label-wrapper--text-input {
    height: 100%;
  }

  .text-area__wrapper {
    height: 100%;
  }
`;

export const TranslationModalContainer = styled(Modal)`
  .prompt__content-wrapper {
    max-width: 95vw;
    height: 80vh;
  }
`;

export const TranslationModalFooter = styled(ModalFooter)`
  justify-content: center !important;
`;
