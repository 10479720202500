import React, { useContext, useEffect, useMemo } from 'react';
import { useLanguageSelection } from '../../hooks/contexts/LanguageContext';
import { formatVersion, getSelectableLanguages } from '../../utils/version';
import { Context as RulesContext } from '../../hooks/contexts/RulesContext';
import Select from '../Select';
import { useLatestByCountryQuery } from '../../__generated__/types';

const formatLanguage = (countryCode: string, languageCode: string): string => `${countryCode}-${languageCode}`;

export const LanguageDropdown = ({ countryCode, refreshRedirect }: { countryCode: string; refreshRedirect?: boolean }) => {
  const { data } = useLatestByCountryQuery({ variables: { country: countryCode } });
  const formattedVersions = useMemo(() => data?.latestVersionsForCountry?.map(formatVersion) ?? [], [data]);
  const selectableLanguages = useMemo(() => getSelectableLanguages(formattedVersions, countryCode), [formattedVersions, countryCode]);
  const { selectedLanguage, setSelectedLanguage } = useLanguageSelection();
  const { setRefetchAfter } = useContext(RulesContext);
  const onSetLanguage = (value: string) => {
    setSelectedLanguage(value);
    if (refreshRedirect) {
      setRefetchAfter(`refresh-redirect-${value}`);
    }
  };

  useEffect(() => {
    if (selectableLanguages.length > 0) {
      const formattedLanguage = formatLanguage(selectableLanguages[0].countryCode, selectableLanguages[0].languageCode);
      onSetLanguage(formattedLanguage);
    }
  }, [selectableLanguages]);

  if (selectableLanguages.length < 2) return null;
  return (
    <Select
      id="languageSelector"
      data-testid={'language-dropdown-select'}
      onChange={(e) => {
        onSetLanguage(e.target.value);
      }}
      value={selectedLanguage}
    >
      {selectableLanguages?.map(({ languageCode, languageName, countryCode }) => (
        <Select.Option key={languageCode} value={formatLanguage(countryCode, languageCode)} label={languageName} />
      ))}
    </Select>
  );
};
