import styled from '@emotion/styled';
import { FlexContainer } from 'components/FlexContainer';
import { colourNeutral3 } from '@ingka/variables/colours-css';

import { radiusS, space100, space75, thicknessThin } from '@ingka/variables';

export const StatusOverviewSection = styled(FlexContainer)`
  border: ${thicknessThin} solid ${colourNeutral3};
  border-radius: ${radiusS};
  padding: ${space75};
  height: 100%;
  gap: ${space100};
`;
