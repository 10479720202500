import React, { FC, Fragment, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Checkbox from '@ingka/checkbox';
import handleBar from '@ingka/ssr-icon/paths/handle';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import Text from '@ingka/text';
import { Column as RuleTableColumn } from 'types';
import { DeleteRuleModal } from '../DeleteRuleModal';
import { RuleType, User } from '../index';
import { StatusIcon } from '../StatusIcon';
import * as Styled from './styles';
import { FloatingMenuWithPillButton, FloatingMenuWithPillMenuItem } from '../../../components/FloatingMenuWithPillButton';
import { formatDate } from '../../../utils/dates';
import { TableCell } from '../../../components/Table/TableCell';

type RuleProps = {
  rule: RuleType;
  onCheckboxClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
  isEditingAllowed: boolean;
  isSelected: boolean;
  columns: RuleTableColumn[];
  isGlobal: boolean;
  id: string;
  hideActions: boolean;
  hideActionsIcon: boolean;
  policy: string;
};

const shortenNumber = (number: number): string => {
  const suffixes = ['', 'k', 'M', 'B', 'T'];
  const suffixIndex = Math.floor(Math.log10(number) / 3);
  const shortNumber = (number / Math.pow(1000, suffixIndex)).toFixed(1);
  return shortNumber + suffixes[suffixIndex];
};

const Rule: FC<RuleProps> = ({
  rule,
  onCheckboxClick,
  onEditClick,
  onDeleteClick,
  isEditingAllowed,
  isSelected,
  columns,
  isGlobal,
  id,
  policy,
  hideActions,
  hideActionsIcon,
}) => {
  const { docRefId, name, invocations, targetURL } = rule;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const invocationsShortend = invocations >= 1000 ? shortenNumber(invocations) : invocations;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: docRefId });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const floatingMenuItems: FloatingMenuWithPillMenuItem[] = [
    {
      onClick: onEditClick,
      text: 'Edit',
      icon: pencil,
      dataTestId: 'edit-rule-modal-button',
    },
    {
      onClick: () => setIsDeleteModalOpen(true),
      text: 'Delete',
      icon: trashCan,
      dataTestId: 'delete-rule-modal-button',
    },
  ];

  const TableCells = columns.map((column: RuleTableColumn) => {
    const cellValue = rule[column.field as keyof RuleType];
    switch (column.field) {
      case 'matchURL':
      case 'targetURL':
      case 'name':
      case 'redirectType':
        return <TableCell text={(cellValue?.toString() as string) || ''} key={column.field} />;
      case 'dateStart':
      case 'dateEnd':
      case 'dateCreated':
      case 'dateModified':
        const formatedDate = cellValue ? formatDate(cellValue as string) : column.field === 'dateEnd' ? 'None' : '';
        return <TableCell text={formatedDate} key={column.field} />;
      case 'userModifiedBy':
      case 'userCreatedBy':
        return <TableCell text={(cellValue as User)?.email} key={column.field} />;
      case 'invocations':
        return <TableCell text={invocationsShortend?.toString()} textAlign={'center'} key={column.field} />;
      case 'status':
        return (
          <TableCell key={column.field}>
            <Text tagName="span" bodySize="s">
              <a href={cellValue as string} target="_blank" rel="noreferrer">
                {cellValue as string}
              </a>
            </Text>
          </TableCell>
        );
      case 'targetStatus':
        return (
          <Fragment key={column.field}>
            {!isGlobal && (
              <TableCell>
                <StatusIcon status={cellValue as number} startDate={rule.dateStart} />
              </TableCell>
            )}
          </Fragment>
        );
      default:
        return null;
    }
  });

  return (
    <tr id={id} ref={setNodeRef} style={style} data-testid={`rule-row`}>
      {isGlobal && (
        <td {...attributes} {...listeners} data-testid={`drag-rule-button-${id}`}>
          <Styled.PillButton iconOnly size={'small'} ssrIcon={handleBar} />
        </td>
      )}
      {!hideActions && (
        <td>
          <Checkbox
            id={docRefId}
            name="selectRowCheckbox"
            data-testid={'rule-checkbox'}
            checked={isSelected}
            onChange={onCheckboxClick}
            value={docRefId}
          />
        </td>
      )}
      {TableCells}
      {(!hideActionsIcon || !hideActions) && (
        <td>
          {!hideActions && (
            <Styled.EditDeleteButtonContainer>
              <Styled.EditDeleteButton
                type={'tertiary'}
                text={'Delete'}
                size={'small'}
                ssrIcon={trashCan}
                onClick={() => setIsDeleteModalOpen(true)}
                disabled={!isEditingAllowed}
                data-testid="delete-rule-modal-button"
              />
              <Styled.EditDeleteButton
                type={'secondary'}
                text={'Edit'}
                size={'small'}
                ssrIcon={pencil}
                data-testid="edit-rule-modal-button"
                onClick={onEditClick}
                disabled={!isEditingAllowed}
              />
            </Styled.EditDeleteButtonContainer>
          )}
          {!hideActionsIcon && <FloatingMenuWithPillButton menuItems={floatingMenuItems} disabled={!isEditingAllowed} />}
          {isDeleteModalOpen && (
            <DeleteRuleModal
              isVisible={isDeleteModalOpen}
              onSubmit={() => {
                onDeleteClick();
                setIsDeleteModalOpen(false);
              }}
              onCancel={() => setIsDeleteModalOpen(false)}
              selectedRules={[docRefId]}
              ruleName={name}
              ruleTargetURL={targetURL}
              policy={policy}
            />
          )}
        </td>
      )}
    </tr>
  );
};

export { Rule };
