import { FC, ReactNode } from 'react';
import Text from '@ingka/text';
import * as Styled from './styles';

type TableCellProps = {
  textAlign?: string;
} & (
  | { text: string; children?: never }
  | {
      children: ReactNode;
      text?: never;
    }
);

export const TableCell: FC<TableCellProps> = ({ text, textAlign, children }) => (
  <Styled.AlignedCell textAlign={textAlign}>
    {text ? (
      <Text tagName="span" bodySize="m">
        {text}
      </Text>
    ) : (
      children
    )}
  </Styled.AlignedCell>
);
