import React, { ReactNode, SyntheticEvent, useRef, useState } from 'react';
import informationIcon from '@ingka/ssr-icon/paths/information';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import * as Styled from './styles';
import { TooltipMessageProps } from './styles';

interface TooltipProps extends TooltipMessageProps {
  children: ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, left, top }) => {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipMessageRef = useRef(null);
  const tooltipButtonRef = useRef(null);
  useOnClickOutside([tooltipMessageRef, tooltipButtonRef], () => setIsOpen(false));

  return (
    <Styled.TooltipContainer ref={tooltipButtonRef} align={'center'}>
      <Styled.ToolTipButton
        ssrIcon={informationIcon}
        type={'tertiary'}
        iconOnly={true}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      />
      {isOpen && (
        <Styled.TooltipMessage ref={tooltipMessageRef} left={left} top={top}>
          {children}
        </Styled.TooltipMessage>
      )}
    </Styled.TooltipContainer>
  );
};
