import styled from '@emotion/styled';
import IngkaSelect from '@ingka/select';

export const Select = styled(IngkaSelect)<{ width?: string }>`
  option[value='Choose an option'] {
    display: none;
  }

  width: ${(props) => props.width || '200px'};
`;
