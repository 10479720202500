import styled from '@emotion/styled';
import { space100, space125 } from '@ingka/variables';

export const PageHeader = styled.h2`
  margin-bottom: ${space125};
`;

export const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${space100};
`;
