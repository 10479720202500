import styled from '@emotion/styled';
import Button from '@ingka/button';
import InputField from '@ingka/input-field';

export { ResizedSelect } from '../styles';

export const ResizedInputField = styled(InputField)`
  max-width: 200px;
  position: relative;
  z-index: 10;
`;

export const PaddedButton = styled(Button)`
  margin: 48px;
  z-index: 10;
`;
