export const getSortFieldLabel = (key: string): string | undefined => {
  return {
    'search_clicks,desc': 'Most clicks',
    'search_clicks,asc': 'Least clicks',
    'search_ctr,desc': 'Highest CTR',
    'search_ctr,asc': 'Lowest CTR',
    'search_impressions,desc': 'Most impressions',
    'search_impressions,asc': 'Least impressions',
    'avg_position,asc': 'Avg. Pos. (Good)',
    'avg_position,desc': 'Avg. Pos. (Bad)',
  }[key];
};
