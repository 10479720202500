import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { DailyRequestsCountMap, getRedirectRequests, RedirectRequestResponseData } from 'api/redirectRequests';
import { useCountry } from '../useCountry';
import { Context as LoginContext } from './LoginContext';
import { useGetLocalesQuery } from '__generated__/types';
import { datePickerOptions, getDateRangeForLast30Days } from '../../features/Dashboard/DatePicker/datePickerOptions';

export const DEFAULT_DATE_RANGE = {
  startDate: getDateRangeForLast30Days().startDate,
  endDate: getDateRangeForLast30Days().endDate,
  timeRange: {
    value: datePickerOptions[3].value,
    label: datePickerOptions[3].label,
  },
};

type DateRange = typeof DEFAULT_DATE_RANGE;

interface SelectedCountry {
  countryCode: string;
  countryName: string;
}

interface RedirectRequestContextType {
  isLoading: boolean;
  errorRedirectsGraphData: boolean;
  selectedCountry: SelectedCountry;
  setSelectedCountry: Dispatch<SetStateAction<SelectedCountry>>;
  redirectsGraphDailyRequestsCount?: DailyRequestsCountMap;
  onGraphDataRefetch: () => void;
  timeFetched: string;
  allLocales?: Array<string>;
  selectedDateRange: DateRange;
  setSelectedDateRange: Dispatch<SetStateAction<DateRange>>;
}

const RedirectRequestsContext = createContext<RedirectRequestContextType | undefined>(undefined);
const excludesFromLocalesList = ['r1', 'global'];

const getCurrentTime = () =>
  new Date().toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });

const RedirectRequestProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [country] = useCountry();
  const defaultState = useMemo(
    () => ({
      countryCode: country?.countryCode ?? '',
      countryName: country?.countryName ?? '',
    }),
    [country?.countryName, country?.countryCode],
  );
  const [selectedCountry, setSelectedCountry] = useState<SelectedCountry>(defaultState);
  const [graphDatarefetch, setGraphDataRefetch] = useState(false);
  const [timeFetched, setTimeFetched] = useState<string>('');
  const [redirectsGraphData, setRedirectsGraphData] = useState<RedirectRequestResponseData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [errorRedirectsGraphData, setErrorRedirectsGraphData] = useState(false);
  const { refreshToken } = useContext(LoginContext);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(DEFAULT_DATE_RANGE);
  useEffect(() => {
    const getRedirectsGraphData = async () => {
      try {
        setErrorRedirectsGraphData(false);
        setIsLoading(true);
        const token = await refreshToken();
        const data = await getRedirectRequests({
          startDate: selectedDateRange?.startDate ?? '',
          endDate: selectedDateRange?.endDate ?? '',
          statusCode: 404,
          countryCode: selectedCountry.countryCode ?? '',
          token,
        });
        setRedirectsGraphData(data[0]);
      } catch (err) {
        setErrorRedirectsGraphData(true);
        console.error(err);
      } finally {
        setIsLoading(false);
        setTimeFetched(getCurrentTime());
      }
    };
    getRedirectsGraphData();
  }, [graphDatarefetch, selectedCountry, selectedDateRange]);

  const { data: localesData } = useGetLocalesQuery();

  const allLocales = useMemo(() => {
    if (!localesData) return;
    const { otherLocales, myLocales } = localesData.locales;
    return [...otherLocales, ...myLocales].reduce(
      (acc, cur) => (acc.includes(cur.country) || excludesFromLocalesList.includes(cur.country) ? acc : [...acc, cur.country]),
      [] as Array<string>,
    );
  }, [localesData]);

  const onGraphDataRefetch = () => {
    setGraphDataRefetch((prev) => !prev);
  };

  return (
    <RedirectRequestsContext
      value={{
        selectedCountry,
        setSelectedCountry,
        redirectsGraphDailyRequestsCount: redirectsGraphData?.dailyRequestsCount,
        isLoading,
        onGraphDataRefetch,
        timeFetched,
        allLocales,
        setSelectedDateRange,
        selectedDateRange,
        errorRedirectsGraphData,
      }}
    >
      {children}
    </RedirectRequestsContext>
  );
};

const useRedirectRequests = () => {
  const context = useContext(RedirectRequestsContext);
  if (context === undefined) {
    throw new Error('useRedirectRequests must be used within a RedirectRequestProvider');
  }
  return context;
};

export { RedirectRequestProvider, useRedirectRequests };
