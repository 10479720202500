import styled from '@emotion/styled';
import { space125 } from '@ingka/variables';

export const ContainerWrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: ${space125};

  .tabs__tab {
    padding: 0rem 0.25rem 0.5rem 0.25rem;
  }
`;
