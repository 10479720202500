import styled from '@emotion/styled';
import { ModalBody, ModalHeader } from '@ingka/modal';
import RadioButtonGroup from '@ingka/radio-button-group';

export const CountryModalHeader = styled(ModalHeader)`
  font-size: 16px;
`;

export const CountryModalRadioButtonGroup = styled(RadioButtonGroup)`
  legend {
    font-size: 16px;
  }

  .radio {
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 26px;
  }

  .radio input[type='radio'] {
    right: -0.625rem;
    left: auto;
  }

  .radio__label {
    width: 100%;
  }

  .radio__label label {
    text-transform: capitalize;
    padding-left: 0;
    width: 100%;
  }
`;

export const CountryFlexedModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 25px;
  padding-right: 25px;
`;
