import { Dispatch } from 'react';
import { RuleType } from 'features/Rules';
import { Action, createDataContext } from './createDataContext';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

type SortParams = { field: string | null; direction: SortDirection | null };
type UploadRulesModalParams = {
  isOpen: boolean;
  country: string;
  policy: string;
};

interface State {
  refetchAfter: string;
  editRuleModalIsOpen: boolean;
  selectedRule: RuleType;
  selectedRulesArray: string[];
  sort: SortParams;
  uploadRulesModal: UploadRulesModalParams;
}

const rulesReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set_sort':
      if (state.sort.field === action.payload && state.sort.direction === SortDirection.ASC) {
        return {
          ...state,
          sort: { ...state.sort, direction: SortDirection.DESC },
        };
      }
      if (state.sort.field === action.payload && state.sort.direction === SortDirection.DESC) {
        return { ...state, sort: { field: null, direction: null } };
      }
      return {
        ...state,
        sort: { field: action.payload, direction: SortDirection.ASC },
      };
    case 'set_sort_field_and_direction':
      return {
        ...state,
        sort: {
          field: action.payload.field,
          direction: action.payload.direction,
        },
      };
    case 'set_reFetch_after':
      return { ...state, refetchAfter: action.payload };
    case 'set_current_rule_value':
      return { ...state, selectedRule: action.payload };
    case 'set_edit_rule_modal_value':
      return { ...state, editRuleModalIsOpen: action.payload };
    case 'set_upload_rules_modal_values':
      return { ...state, uploadRulesModal: action.payload };
    default:
      return state;
  }
};

const setSortOnField = (dispatch: Dispatch<Action>) => (field: string) => {
  dispatch({ type: 'set_sort', payload: field });
};
const setSortOnFieldAndDirection = (dispatch: Dispatch<Action>) => (sortFieldAndDirection: SortParams) => {
  dispatch({
    type: 'set_sort_field_and_direction',
    payload: sortFieldAndDirection,
  });
};

const setRefetchAfter = (dispatch: Dispatch<Action>) => (refetchAfter: string) => {
  dispatch({ type: 'set_reFetch_after', payload: refetchAfter });
};

const setSelectedRuleValue = (dispatch: Dispatch<Action>) => (selectedRule: RuleType) => {
  dispatch({ type: 'set_current_rule_value', payload: selectedRule });
};

const setEditRuleModalIsOpenValue = (dispatch: Dispatch<Action>) => (editRuleModalIsOpen: boolean) => {
  dispatch({ type: 'set_edit_rule_modal_value', payload: editRuleModalIsOpen });
};

const setUploadRulesModalValues = (dispatch: Dispatch<Action>) => (uploadRulesModal: UploadRulesModalParams) => {
  dispatch({
    type: 'set_upload_rules_modal_values',
    payload: uploadRulesModal,
  });
};

export const { Provider, Context } = createDataContext<State>(
  rulesReducer,
  {
    setRefetchAfter,
    setSelectedRuleValue,
    setEditRuleModalIsOpenValue,
    setUploadRulesModalValues,
    setSortOnField,
    setSortOnFieldAndDirection,
  },
  {
    sort: { field: null, direction: SortDirection.DESC },
    refetchAfter: '',
    selectedRule: {
      name: '',
      matchURL: '',
      targetURL: '',
      dateStart: '',
      targetStatus: 0,
      userCreatedBy: { fullName: '', email: '' },
      userModifiedBy: { fullName: '', email: '' },
      redirectType: 0,
      dateEnd: '',
      dateCreated: '',
      dateModified: '',
      invocations: 0,
      docRefId: '',
    },
    selectedRulesArray: [],
    editRuleModalIsOpen: false,
    uploadRulesModal: { isOpen: false, country: '', policy: '' },
  },
);
