import styled from '@emotion/styled';

export const AutosuggestWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  padding: 1rem;
`;

export const AutosuggestBox = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -1rem;
  flex-direction: column;
  background-color: white;
  border: 1px solid lightgray;

  & span {
    padding: 0.5rem;
    border-bottom: 1px solid #eee;
  }

  & span:last-child {
    border-bottom: none;
  }

  & span:hover {
    background-color: #eee;
    cursor: pointer;
    display: block;
  }
`;
