import styled from '@emotion/styled';
import FormField from '@ingka/form-field';

export const MarketFilterContainer = styled(FormField)`
  display: flex;
`;
export const TooltipWrapper = styled.div`
  position: relative;
`;
export const TooltipContent = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid grey;
  padding: 1rem;
  width: 200px;
`;

export const ExtraInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ExtraInfoHeading = styled.h4`
  margin-bottom: 0.6rem;
`;
