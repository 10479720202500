import React, { useState } from 'react';
import Text from '@ingka/text';
import { FlexContainer } from '../../../components/FlexContainer';
import { ProfileImage } from '../../Header/ProfileImage';
import { MetaDataComment } from '../EditSheetComments';
import { trashCan } from '@ingka/ssr-icon/icons';
import Button from '@ingka/button';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import * as Styled from './styles';

export type EditSheetContentSidebarCommentProps = Pick<MetaDataComment, 'text' | 'createdAt' | 'image' | 'displayName'> & {
  id: string;
  isDeleteButtonEnabled: boolean;
  onDelete: () => Promise<void>;
};

export const EditSheetContentSidebarComment: React.FC<EditSheetContentSidebarCommentProps> = ({
  id,
  displayName,
  text,
  image,
  createdAt,
  onDelete,
  isDeleteButtonEnabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsModalOpen(false);
    await onDelete();
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };
  return (
    <FlexContainer gap={0.5} direction="column">
      <FlexContainer gap={0.5} justify={'space-between'}>
        <FlexContainer gap={0.5}>
          <ProfileImage image={image} variant="primary" />
          <FlexContainer direction="column">
            <Styled.NameText tagName={'h4'} bodySize={'m'} style={{ whiteSpace: 'nowrap' }}>
              {truncateName(displayName)}
            </Styled.NameText>
            <Text tagName={'span'} bodySize={'s'}>
              {toReadableTimeString(createdAt)}
            </Text>
          </FlexContainer>
        </FlexContainer>
        {isDeleteButtonEnabled && (
          <Button
            data-testid={`delete-comment-button-${id}`}
            iconOnly={true}
            type={'tertiary'}
            size={'xsmall'}
            ssrIcon={trashCan}
            onClick={handleDeleteClick}
          />
        )}
      </FlexContainer>
      <Modal escapable={false} visible={isModalOpen} handleCloseBtn={handleCancelDelete}>
        <Prompt
          aria-label="Accessibility header for a modal"
          className="example-prompt-override"
          footer={
            <ModalFooter>
              <Button text="Cancel" type="secondary" data-testid={'cancel-delete'} onClick={handleCancelDelete} />
              <Button text="Delete" type="primary" data-testid={'confirm-delete'} onClick={handleConfirmDelete} />
            </ModalFooter>
          }
          header={<ModalHeader ariaCloseTxt="Close button text" />}
          title="Confirm Deletion"
          titleId="prompt-title-id"
        >
          <p>Are you sure you want to delete this comment?</p>
        </Prompt>
      </Modal>
      <Styled.CommentText>{text}</Styled.CommentText>
    </FlexContainer>
  );
};

const truncateName = (displayName: string): string => {
  const isNameLengthOk = (name: string) => name.length <= 20;

  if (isNameLengthOk(displayName)) {
    return displayName;
  }
  const names = displayName.split(' ');
  const firstName = names[0];
  const lastName = names[1];

  const truncatedLastName = `${firstName} ${lastName[0]}.`;
  const initials = `${firstName[0]}. ${lastName[0]}.`;

  if (isNameLengthOk(truncatedLastName)) {
    return truncatedLastName;
  }
  return initials;
};

const toReadableTimeString = (date: Date): string => {
  const today = new Date();
  // Reset hours, minutes, seconds, and milliseconds for comparison
  today.setHours(0, 0, 0, 0);

  const inputDate = new Date(date);
  inputDate.setHours(0, 0, 0, 0);

  const differenceInTime = inputDate.getTime() - today.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert time difference to days

  const timeString = date.toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  });
  let dateString;
  if (differenceInDays === 0) {
    dateString = 'Today';
  } else if (differenceInDays === -1) {
    dateString = 'Yesterday';
  } else {
    dateString = inputDate.toLocaleDateString();
  }
  return `${dateString} at ${timeString}`;
};
