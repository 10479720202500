import React from 'react';
import { MarketComponentProps } from 'types/sitemaps';
import { marketKey } from '../index';
import { FlexContainer } from '../../../../components/FlexContainer';

export const Market: React.FC<MarketComponentProps> = ({ className, country, language }) => (
  <FlexContainer
    className={className}
    data-testid={marketKey({
      country: country || '',
      language: language || '',
    })}
  >
    <p>
      {language}-{country}
    </p>
  </FlexContainer>
);
